
import { SkeletonText } from "@chakra-ui/react";
import React from 'react';

export const toCurrency = (numberString) => {
    let number = parseFloat(numberString);
    return number.toLocaleString('USD');
}

export function formatPercent(number) {
    return (parseFloat(number*100).toFixed(0)+'%')
}

const columnMap = [
    {
        Header: 'Dem. Spend',
        accessor: 'd_spend',
        isNumeric: true,
        isColor: false,
        Cell: props => <div> ${toCurrency(Math.round(props.value))} </div>,
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => {
                  return row?.d_spend + sum
              }, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> ${toCurrency(Math.round(total))} </SkeletonText> )}
      },
    {
      Header: 'Dem. GRPs',
      accessor: 'd_grps',
      isNumeric: true,
      isColor: false,
      Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
      Footer: (info,t) => {
          const total = React.useMemo(
            () =>
              info.data.reduce((sum, row) => {
                return row?.d_grps + sum
            }, 0),
            [info.data]
          )
          return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
    },
      {
        Header: 'Dem. Spots',
        accessor: 'd_spots',
        isNumeric: true,
        isColor: false,
        Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
        borderRight: '1px solid black',
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => {
                  return row?.d_spots + sum
              }, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
      },
      {
        Header: 'Rep. Spend',
        accessor: 'r_spend',
        isNumeric: true,
        isColor: false,
        Cell: props => <div> ${toCurrency(Math.round(props.value))} </div>,
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => {
                  return row?.r_spend + sum
              }, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> ${toCurrency(Math.round(total))} </SkeletonText> )}
      },
    {
      Header: 'Rep. GRPs',
      accessor: 'r_grps',
      isNumeric: true,
      isColor: false,
      Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
      Footer: (info,t) => {
          const total = React.useMemo(
            () =>
              info.data.reduce((sum, row) => {
                return row?.r_grps + sum
            }, 0),
            [info.data]
          )
          return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
    },
      {
        Header: 'Rep. Spots',
        accessor: 'r_spots',
        isNumeric: true,
        isColor: false,
        Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
        borderRight: '1px solid black',
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => {
                  return row?.r_spots + sum
              }, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
      },
        {
          Header: 'Dem. Spend Share',
          accessor: 'd_spend_share',
          isNumeric: true,
          sortType: 'number',
          Cell: props => <div> {formatPercent(props.value)} </div>,
          Footer: (info,t) => {
              const total = React.useMemo(
                () =>
                  info.data.reduce((sum, row) => row?.d_spend_share + sum, 0),
                [info.data]
              )
              return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
      },
        {
          Header: 'Dem. GRPs Share',
          accessor: 'd_grps_share',
          isNumeric: true,
          sortType: 'number',
          Cell: props => <div> {formatPercent(props.value)} </div>,
          Footer: (info,t) => {
              const total = React.useMemo(
                () =>
                  info.data.reduce((sum, row) => row?.d_grps_share + sum, 0),
                [info.data]
              )
              return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
      },
      {
        Header: 'Dem. Spots Share',
        accessor: 'd_spots_share',
        isNumeric: true,
        borderRight: '1px solid black',
        sortType: 'number',
        Cell: props => <div> {formatPercent(props.value)} </div>,
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => row?.d_spots_share + sum, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
    },
    {
      Header: 'Rep. Spend Share',
      accessor: 'r_spend_share',
      isNumeric: true,
      sortType: 'number',
      Cell: props => <div> {formatPercent(props.value)} </div>,
      Footer: (info,t) => {
          const total = React.useMemo(
            () =>
              info.data.reduce((sum, row) => row?.r_spend_share + sum, 0),
            [info.data]
          )
          return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
  },
    {
      Header: 'Rep. GRPs Share',
      accessor: 'r_grps_share',
      isNumeric: true,
      sortType: 'number',
      Cell: props => <div> {formatPercent(props.value)} </div>,
      Footer: (info,t) => {
          const total = React.useMemo(
            () =>
              info.data.reduce((sum, row) => row?.r_grps_share + sum, 0),
            [info.data]
          )
          return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
  },
  {
    Header: 'Rep. Spots Share',
    accessor: 'r_spots_share',
    isNumeric: true,
    sortType: 'number',
    borderRight: '1px solid black',
    Cell: props => <div> {formatPercent(props.value)} </div>,
    Footer: (info,t) => {
        const total = React.useMemo(
          () =>
            info.data.reduce((sum, row) => row?.r_spots_share + sum, 0),
          [info.data]
        )
        return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
  },
  {
    Header: 'Spending Margin',
    accessor: 'spending_diff',
    isNumeric: true,
    isColor: false,
    Cell: props => <div> ${toCurrency(Math.round(props.value))} </div>,
    Footer: (info,t) => {
        const total = React.useMemo(
          () =>
            info.data.reduce((sum, row) => {
              return row?.spending_diff + sum
          }, 0),
          [info.data]
        )
        return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> ${toCurrency(Math.round(total))} </SkeletonText> )}
  },
{
  Header: 'GRPs Margin',
  accessor: 'grps_diff',
  isNumeric: true,
  isColor: false,
  Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
  Footer: (info,t) => {
      const total = React.useMemo(
        () =>
          info.data.reduce((sum, row) => {
            return row?.grps_diff + sum
        }, 0),
        [info.data]
      )
      return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
},
  {
    Header: 'Spots Margin',
    accessor: 'spots_diff',
    isNumeric: true,
    isColor: false,
    Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,

    Footer: (info,t) => {
        const total = React.useMemo(
          () =>
            info.data.reduce((sum, row) => {
              return row?.spots_diff + sum
          }, 0),
          [info.data]
        )
        return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
  },
]

export const genColumns = (category) => {
    let cols = []
    category = !category ? 'Race' : category
    cols.push({'Header': category, 
    'accessor': 'cat_val', 
    'isNumeric':false,
    Footer: 'Total:',
    font: 'Texta Alt Heavy',
    sortType: (ra, rb, colid, desc)=>{
        const manualSortDict = {
            'Weekday': {
              'Sunday': 7,
              'Saturday': 6,
            'Friday': 5,
            'Thursday': 4,
            'Wednesday': 3,
            'Tuesday': 2,
            'Monday': 1
            },
            'Day Part': {
              'Overnight': 6,
            'Late Fringe': 5,
            'Primetime': 4,
            'Early Fringe': 3,
            'Daytime': 2,
            'Early AM': 1
            }
        }
        ra = ra.values[colid]
        rb = rb.values[colid]
        if (category in manualSortDict){
            ra = manualSortDict[category][ra];
            rb = manualSortDict[category][rb];
        }
        let sortVal = ra>rb ? 1 : -1
        sortVal = desc ? sortVal*-1 : sortVal
        return sortVal;
        }
    })
    cols = cols.concat(columnMap)
    return cols
  }

  export const genWideColumns = (category, data) => {
    let cols = []
    category = !category ? 'Race' : category
    cols.push({'Header': category === 'county_name' ? 'County' : 'Turnout Score',  // TODO ADD THIS AS A PARAMETER
                'accessor': category,
                'isNumeric':false,
                Footer: 'Total:',
                font: 'Texta Alt Heavy',
                sortType: (ra, rb, colid, desc)=>{
                    const manualSortDict = {
                        'Modeled College Likelihood': {
                          'High Likely': 5,
                          'Likely': 4,
                          'Uncertain': 3,
                          'Unlikely': 2,
                          'High Unlikely': 1
                        },
                        'Modeled Dem Support': {
                          'Strong Dem': 5,
                          'Lean Dem': 4,
                          'Uncertain': 3,
                          'Lean GOP': 2,
                          'Strong GOP': 1
                        }
                    }
                    ra = ra.values[colid]
                    rb = rb.values[colid]
                    if (category in manualSortDict){
                        ra = manualSortDict[category][ra];
                        rb = manualSortDict[category][rb];
                    }
                    let sortVal = ra>rb ? 1 : -1
                    sortVal = desc ? sortVal*-1 : sortVal
                    return sortVal;
                    }
    })

    if (data && data.length>0){
      Object.keys(data[0]).forEach(k=>{
        if (k!==category){
            cols.push({'Header': k.replace('_',' '), 
            'accessor': k, 
            'isNumeric':true,
            Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
            Footer: (info,t) => {
              const total = React.useMemo(
                () =>
                  info.data.reduce((sum, row) => {
                    return row[k] + sum
                }, 0),
                [info.data]
              )
              return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )},
            font: 'Texta Alt Regular'
          })
        }
      })
    }

    return cols
  }
const getElectionDates = (d) => {
  console.log(d)
  const electionDates = {
    '2016': { startdate: '01-01-2015', enddate: '11-08-2016'},
    '2018': { startdate: '01-01-2017', enddate: '11-06-2018'},
    '2020': { startdate: '01-01-2019', enddate: '11-03-2020'}
  }
  const cycles = ['2016','2018','2020']
  let returnVal;
  cycles.forEach(y=>{
    if (d.indexOf(y) !== -1){
      console.log(y)
      returnVal = electionDates[y]
    }
  })
  return returnVal;
}

export const genMenuItems = (data, key_val, addToPayload) => {
  console.log(key_val)
  if (key_val === 'election') {
      return data.map(d=>{
        if (typeof d === 'string' || d instanceof String) {
            let payloadObj = {}
            payloadObj[key_val] = d
            if (addToPayload) {
                payloadObj = {...payloadObj, ...addToPayload}
            }
            let edates = getElectionDates(d)
            payloadObj = {...payloadObj, startdate: new Date(edates.startdate),enddate: new Date(edates.enddate)}
            console.log(payloadObj)
            return {id: d, text: d, payload: payloadObj}
        } else return d
    })
  } else {
    return data.map(d=>{
      if (typeof d === 'string' || d instanceof String) {
          let payloadObj = {}
          payloadObj[key_val] = d
          if (addToPayload) {
              payloadObj = {...payloadObj, ...addToPayload}
          }
          return {id: d, text: d, payload: payloadObj}
      } else return d
  })
  }

}

export function median(values){
  if(values.length ===0) throw new Error("No inputs");

  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);
  
  if (values.length % 2)
    return values[half];
  
  return (values[half - 1] + values[half]) / 2.0;
}
