import {
  Box, Portal, SkeletonText, Table,
  Tbody,
  Td, Tfoot, Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { scaleLinear } from "d3-scale";
import React, { useEffect, useState } from "react";
import { useExpanded, useSortBy, useTable } from "react-table";


const calcBackground = (data, cell,state) => {
  let col_vals = data.map((d) => d[cell.column.id]);
  col_vals.sort( (a,b) => { 
    let returnVal = a > b ? 1 : -1
    return returnVal
  });
  let val = cell.column.colorAccessor ? cell.row.values[cell.column.colorAccessor] : cell.value

  if ((col_vals[0] !== undefined) & cell.column.isColor) {
    let colDomain;
    let colorVal;
    if(!cell.column.colorType) {
      colDomain = [0, 1];
      if(!cell.column.colors) {
        colorVal = scaleLinear()
        .domain(colDomain)
        .range(["#FFFFFF", "#ff6d2d"]);
      }
    } else {
      colDomain = state==='US' ? [-.1,0,.1] : [-1,0,1]
      colDomain = cell.column.colorBounds ? cell.column.colorBounds : colDomain
      if(cell.column.colors?.length > 0) {
        colorVal = scaleLinear()
        .domain(colDomain)
        .range(cell.column.colors);

      } else {
        colorVal = scaleLinear()
        .domain(colDomain)
        .range(["#FFFFFF", "#ff6d2d"]);
      }
    }
    let returnColor = colorVal(val);
    if ((val>1) || (val<-1)){
      returnColor = 'white'
    }
    return returnColor
  } else {
    return "white";
  }
};

const ColumnHeader = (props) => {
  const column = props.column;
  const [tooltip, setTooltip] = useState(null);
  let timeout;
  const TooltipBox = (props) => {
    return props.info ? (
      <Portal>
        <Box
          position="absolute"
          bg="brand.100"
          color="white"
          zIndex="1000"
          maxWidth="200px"
          textAlign="left"
          left={props.info.x}
          top={props.info.y + props.info.height}
          padding="12px"
          boxShadow="0px 4px 6px 0px rgb(0,0,0,20%)"
          flexDirection="column"
        >
          <Box textTransform="none"> {props.getTooltipText(props.info.id, column.etype, column.Header)} </Box>
        </Box>
      </Portal>
    ) : null;
  };

  return (
    <Th
      {...column.getHeaderProps(column.getSortByToggleProps())}
      isNumeric={column.isNumeric}
      title=""
      onMouseEnter={(m) => {
        timeout = setTimeout(() => {
          var rect = m.target.getBoundingClientRect();
          setTooltip({
            x: rect.left,
            y: rect.top,
            id: column.id,
            height: rect.height,
          });
        }, props.delay || 100);
      }}
      onMouseLeave={(m) => {
        clearInterval(timeout);
        setTooltip(null);
      }}
    >
        <TooltipBox info={tooltip} getTooltipText={props.getTooltipText} />
        {column.render("Header")}
    </Th>
  );
};
//

var ExplorerTable = function (props) {
  const data = React.useMemo(
    () => (props.loading ? Array(5).fill({}) : props.tableData),
    [props.tableData, props.loading]
  );

  const columns = React.useMemo(
    () =>
      props.loading
        ? props.columns.map((column) => ({
            ...column,
            Cell: <SkeletonText noOfLines={1} mt="100p" />,
          }))
        : props.columns,
    [props.columns, props.loading]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    setHiddenColumns,
    state: {expanded} // eslint-disable-line
  } = useTable({ columns, data, initialState: { 
    sortBy: [ {id:'marginperc', desc:true}, {id: 'cat_val', desc: false} ],
    hiddenColumns: props?.etype==='modeled' ? ['reg'] : [],  autoResetHiddenColumns:false
  }, hiddenColumns: props?.etype==='modeled' ? ['reg'] : [],  autoResetHiddenColumns:false },  useSortBy, useExpanded);

  
  useEffect(()=>{
    setHiddenColumns(['reg'])
  },[data,setHiddenColumns])
  //const sortWithNeg = React.useMemo( )

  return (
      <Table
        {...getTableProps()}
        size="sm"
        variant="simple"
        w={props.state !== 'US' ? "min-content" : '60%'}
        overflowX="visible"
        margin="auto"
        overflowY="scroll"
      >
        <Thead position="sticky">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return <ColumnHeader column={column} key={column.id} getTooltipText={props.getTooltipText} />;
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} overflowY="scroll" maxHeight="200px">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                _last={{ td: { borderBottom: "0px solid black" } }}
              >
                {row.cells.map((cell) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      bg={() => calcBackground(data, cell,props.state)}
                    >
                      {cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
        {props.state !== 'US' && <Tfoot>
          {footerGroups.map((group) => (
            <Tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <Td {...column.getFooterProps()} isNumeric={column.isNumeric}>
                  {column.render("Footer")}
                </Td>
              ))}
            </Tr>
          ))}
        </Tfoot> }
      </Table>
  );
};

export default ExplorerTable;