import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { SkeletonText } from "@chakra-ui/react";
import React from 'react';


export function getCategories(data, col) {
    let cats = []
    let filtered = data.filter(d=>{
        return d['NAME']===data[0]['NAME']
    })
    filtered.forEach(f=>{
        if (cats.indexOf(f[col])===-1) {
            cats.push(f[col])
        }
    })
    return cats
}


export const toCurrency = (numberString) => {
    let number = parseFloat(numberString);
    return number.toLocaleString('USD');
}

export function formatPercent(number) {
    return (parseFloat(number*100).toFixed(0)+'%')
}

export function median(values){
    if(values.length ===0) throw new Error("No inputs");
  
    values.sort(function(a,b){
      return a-b;
    });
  
    var half = Math.floor(values.length / 2);
    
    if (values.length % 2)
      return values[half];
    
    return (values[half - 1] + values[half]) / 2.0;
  }

// CAT_VAL 


const columnMap = [
          {
          etype:'modeled',
            Header: 'Est. Turnout',
            accessor: 'voted',
            isNumeric: true,
            isColor: false,
            Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
            Footer: (info,t) => {
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => {
                      return row?.voted + sum
                  }, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
          },
          {
            etype:'modeled',
              Header: 'Est. Turnout Rate',
              accessor: 'rate',
              isNumeric: true,
              isColor: false,
              sortType: 'number',
              Cell: props => <div> {formatPercent(props.value)}</div>,
              Footer: (info,t) => {
                  const regTotal = React.useMemo(
                    () =>
                      info.data.reduce((sum, row) => row?.reg + sum, 0),
                    [info.data]
                  )
                  const voteTotal = React.useMemo(
                    () =>
                      info.data.reduce((sum, row) => row?.voted + sum, 0),
                    [info.data]
                  )
                  return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(voteTotal)}> {formatPercent(voteTotal/regTotal)} </SkeletonText> )}
          }, 
          {
              etype:'modeled',
              id: 'reg',
              accessor: 'reg',
              Header: "rregss",
              isNumeric: true,
              toggleHidden: true,
              isColor: false,
              sortType: 'number',
          }, 
          {
            etype:'modeled',
              Header: 'Est. Vote Share',
              accessor: 'voted_share',
              isNumeric: true,
              isColor: true,
              sortType: 'number',
              Cell: props => <div> {formatPercent(props.value)} </div>,
              Footer: (info,t) => {
                  const total = React.useMemo(
                    () =>
                      info.data.reduce((sum, row) => row?.voted_share + sum, 0),
                    [info.data]
                  )
                  return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
          },
        {
          etype:'modeled',
            Header: 'Est. Democratic Votes',
            accessor: 'dvote',
            isNumeric: true,
            isColor: false,
            sortDescFirst: true,
            Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
            Footer: (info,t) => {
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.dvote + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}

        }, 
        {
          etype:'modeled',
          Header: (props) => <div key="dperc"> % </div>,
            accessor: 'dperc',
            id: 'dperc',
            isNumeric: true,
            sortDescFirst: true,
            isColor: false,
            sortType: 'number',
            Cell: props => <div> {formatPercent(props.value)} </div>,
            Footer: (info,t) => {
                const dvote = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.dvote + sum, 0),
                  [info.data]
                )
                const rvote = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.rvote + sum, 0),
                  [info.data]
                )
                const dperc = dvote/(rvote+dvote)
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(dperc)}> {formatPercent(dperc)} </SkeletonText> )}
        },
        {
          etype:'modeled',
            Header: 'Est. Republican Votes',
            accessor: 'rvote',
            isNumeric: true,
            sortDescFirst: true,
            isColor: false,
            Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
            Footer: (info,t) => {
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.rvote + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}

        }, 
        {
          etype:'modeled',
          Header: (props) => <div key="rperc"> % </div>,
            accessor: 'rperc',
            id: 'rperc',
            isNumeric: true,
            isColor: false,
            sortType: 'number',
            sortDescFirst: true,
            Cell: props => <div> {formatPercent(props.value)} </div>,
            Footer: (info,t) => {
                const dvote = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.dvote + sum, 0),
                  [info.data]
                )
                const rvote = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.rvote + sum, 0),
                  [info.data]
                )
                const rperc = rvote/(rvote+dvote)
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(rperc)}> {formatPercent(rperc)} </SkeletonText> )}
        },
        {
          etype:'modeled',
            Header: 'Est. Margin',
            accessor: 'margin',
            sortType: (a,b,col) => { // For whatever reason sortType number can't sort negatives.
              let returnVal = a.values[col] > b.values[col] ? 1 : -1
              return returnVal
            },
            colorAccessor: 'marginperc',
            colorType: 'diverging',
            colors: ['#e8413d', "#FFFFFF", '#0585c8'],
            isNumeric: true,
            sortDescFirst: true,
            isColor: true,
            Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
            Footer: (info,t) => {
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.margin + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}

        }, 
        {
          etype:'modeled',
            Header: (props) => <div key="marginperc"> % </div>,
            accessor: 'marginperc',
            id: 'marginperc',
            isNumeric: true,
            sortDescFirst: true,
            isColor: true,
            colorType: 'diverging',
            colors: ['#e8413d', "#FFFFFF", '#0585c8'],
            sortType: (a,b,col) => { // For whatever reason sortType number can't sort negatives.
              let returnVal = a.values[col] > b.values[col] ? 1 : -1
              return returnVal
            },
            Cell: props => <div> {formatPercent(props.value)} </div>,
            Footer: (info,t) => {
                const dvote = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.dvote + sum, 0),
                  [info.data]
                )
                const rvote = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.rvote + sum, 0),
                  [info.data]
                )
                const margin = (dvote/(rvote+dvote)) - (rvote/(dvote+rvote))
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(margin)}> {formatPercent(margin)} </SkeletonText> )}
        },
 
        {
          etype:'historic',
            Header: 'Registration',
            accessor: 'reg',
            isNumeric: true,
            isColor: false,
            Cell: props => <div> {toCurrency(props.value)} </div>,
            Footer: (info,t) => {
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.reg + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(total)} </SkeletonText> )}

        }, 

        {
          etype:'historic',
            Header: 'Votes Cast',
            accessor: 'voted',
            isNumeric: true,
            isColor: false,
            sortType: 'number',
            Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
            Footer: (info,t) => {
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.voted + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
        }, 
        {
          etype:'historic',
            Header: 'Turnout Rate',
            accessor: 'rate',
            isNumeric: true,
            isColor: true,
            sortType: 'number',
            Cell: props => <div> {formatPercent(props.value)}</div>,
            Footer: (info,t) => {
                const regTotal = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.reg + sum, 0),
                  [info.data]
                )
                const voteTotal = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.voted + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(voteTotal)}> {formatPercent( ((voteTotal/regTotal))  )} </SkeletonText> )
          }
        }, 
        {
          etype:'historic',
            Header: 'Registration Share',
            accessor: 'reg_share',
            isNumeric: true,
            isColor: true,
            sortType: 'number',
            Cell: props => <div> {formatPercent(props.value)} </div>,
            Footer: (info,t) => {
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.reg_share + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
        },
        {
          etype:'historic',
            Header: 'Vote Share',
            accessor: 'voted_share',
            isNumeric: true,
            isColor: true,
            sortType: 'number',
            Cell: props => <div> {formatPercent(props.value)} </div>,
            Footer: (info,t) => {
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.voted_share + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
        },
        {
          etype:'historic',
            Header: 'Vote Share - Registration Difference',
            accessor: 'diff',
            isNumeric: true,
            isColor: true,
            colorType: 'diverging',
            colors: ['#e8413d', "#FFFFFF", '#005868'],
            colorBounds: [-1,0,1],
            sortType: (a,b,col) => { // For whatever reason sortType number can't sort negatives.
              let returnVal = a.values[col] > b.values[col] ? 1 : -1
              return returnVal
            },
            Cell: props => {
              return (<div> {formatPercent(props.value)} </div>)},
            Footer: (info,t) => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                  () =>
                    info.data.reduce((sum, row) => row?.diff + sum, 0),
                  [info.data]
                )
                return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
        }
]

export const genSidebarRows = (rowData, query, twoWayData) => {
  let sidebar = {}
  let categories = rowData?.map(item => item?.category)
                      .filter((value, index, self) => self.indexOf(value) === index)
  let categoryPlaintext = {'historic': 'DEMOGRAPHIC', 'modeled': 'PROJECTED 2022'}
  //categories = categories.map(c=>categoryPlaintext[c])
  categories?.forEach(c=>{
    let rowDataFiltered = rowData.filter(r=>r.category===c)
    let categoryRows = []
    if(c==='modeled'){
      categoryRows.push({
        id: 'Statemodeled',
        text: 'TOPLINE',
        cat: 'modeled',
        queryPayload: {
          category: 'State', 
          state: 'US', 
          cycle:"22", 
          etype: 'modeled'
        }
      })
    }
    rowDataFiltered.forEach(r=>{
      let payload;
      let menuOptions = []
      if (r.category==='historic' && query.cycle==='22'){
        payload = {category: r.link, mapsub: undefined, mapval: undefined,etype: r.category, cycle: 20, state: query.state === 'US' ? undefined : query.state}
      } else {
          if (r.category==='modeled' && query.etype==='historic') {
              payload = {category: r.link, mapsub: undefined, mapval: undefined,etype: r.category, cycle: 22, state: query.state === 'US' ? undefined : query.state }
          } else {
              payload = {category: r.link, mapsub: undefined, mapval: undefined,etype: r.category, state: query.state === 'US' ? undefined : query.state}
          }
      }
      if(r.type === 'dropdown'){
        r.items.forEach(i=>{
          if (r.category==='historic' && query.cycle==='22'){
            payload = {category: i, mapsub: undefined, mapval: undefined,etype: r.category, cycle: 20, state: query.state === 'US' ? undefined : query.state}
          } else {
              if (r.category==='modeled' && query.etype==='historic') {
                  payload = {category: i, mapsub: undefined, mapval: undefined,etype: r.category, cycle: 22, state: query.state === 'US' ? undefined : query.state }
              } else {
                  payload = {category: i, mapsub: undefined, mapval: undefined,etype: r.category, state: query.state === 'US' ? undefined : query.state}
              }
          }
          menuOptions.push({
            text: i,
            id: i+r.category,
            cat: r.category,
            queryPayload: payload
          })
        })
      }
      categoryRows.push({
        text: r.link,
        id: r.link+r.category,
        cat: r.category,
        queryPayload: payload,
        type: r.type ? r.type : 'link',
        items: r.type ? menuOptions : undefined
      })
    })
    sidebar[c] = categoryRows
  })
  Object.keys(sidebar).forEach(k=>{
    sidebar[categoryPlaintext[k]] = sidebar[k]
    delete sidebar[k]
  })
  return sidebar
}


export const genColumns = (category,etype,state) => {
  let cols = []
  category = state !== 'US' ? category : "State" 
  cols.push({'Header': category, 
  'accessor': 'cat_val', 
  'isNumeric':false,
  Footer: 'Total:',
  sortType: (ra, rb, colid, desc)=>{
    const manualSortDict = {
      'Modeled College Likelihood': {
        'High Likely': 5,
        'Likely': 4,
        'Uncertain': 3,
        'Unlikely': 2,
        'High Unlikely': 1
      },
      'Modeled Dem Support': {
        'Strong Dem': 5,
        'Lean Dem': 4,
        'Uncertain': 3,
        'Lean GOP': 2,
        'Strong GOP': 1
      },
      'Ideology':{
        'Very Liberal': 5,
        'Somewhat Liberal': 4,
        'Moderate': 3,
        'Somewhat Conservative': 2,
        'Very Conservative': 1
      }
    }
    ra = ra.values[colid]
    rb = rb.values[colid]
    if (category in manualSortDict){
      ra = manualSortDict[category][ra];
      rb = manualSortDict[category][rb];
    }
    let sortVal = ra>rb ? 1 : -1
    sortVal = desc ? sortVal*-1 : sortVal
    return sortVal;
  }
})
  if(etype==='historic'){
    cols = cols.concat(columnMap.filter(c=>c.etype==='historic'))
  }
  else{
    if (state!=='US') { 
      cols = cols.concat(columnMap.filter(c=>{
        return c.etype==='modeled'
      }))
    } else {
      cols = cols.concat(columnMap.filter(c=>{
        return (c.etype==='modeled' & c.accessor!=='voted_share')
      }))
    }
  }
  if (state!=='US' & category==='Media Market'){
    cols = [{
      // Build our expander column
      id: 'expander', // Make sure it has an ID
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? <MinusIcon color="brand.300" />  : <AddIcon color="brand.300" />}
        </span>
      ),
      Cell: ({ row }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? <MinusIcon color="brand.300" />  : <AddIcon color="brand.300" />}
          </span>
        ) : null,
    }].concat(cols).concat([{
        Header: "Spill",
        id:'percent_in_state',
        accessor: 'percent_in_state',
        isNumeric: true,
        isColor: true,
        sortType: 'number',
        Cell: props => <div> {formatPercent(props.value)} </div>,
      }])
  }
  return cols
}

