import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Box, Center, ChakraProvider, Spinner } from "@chakra-ui/react";
import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Router, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Comparator from '../comparator/Comparator.js';
import Explorer from '../elecexplorer/Explorer.js';
import TVExplorer from '../tv_explorer/TVExplorer.js';
import UniverseExplorer from '../universe_explorer/UniverseExplorer.js';
import Header from './components/Header.js';
import theme from './Theme.js';
const history = createBrowserHistory();
const queryClient = new QueryClient()

export default function hayden() {
  const onRedirectCallback = (appState) => {
    history.replace(appState?.returnTo || window.location.pathname);
  };

  return (
    <ChakraProvider theme={theme}>
      <Auth0Provider
      domain="login.dscc.io"
      clientId="RocPZWCWPP8V8GZ9jg2k3Hd70FDLQtv9"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      audience={process.env.REACT_APP_BACKEND_API_AUDIENCE}
      scope="read:explorer">
        <MainWindow />
      </Auth0Provider>
    </ChakraProvider>
  );
}

const BaseTemplate = () => { // ADD PAGES HERE
  useEffect(()=>{
    if(history.location.pathname==='/'){
      history.push('explorer?category=Gender&cycle=20&etype=historic&state=AZ')
    }
  },[])
  return (
      <Switch>
          <Route path='/explorer'>  <Explorer /> </Route>
          <Route path='/field/universe_explorer'> <UniverseExplorer/> </Route>
          <Route path='/tv/tv_explorer'> <TVExplorer/> </Route>
          <Route path='/comparator'>  <Comparator /> </Route>
      </Switch>
  )
}

const MainWindow = () => {
  const {isAuthenticated} = useAuth0();

  return (
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
      <QueryParamProvider ReactRouterRoute={Route}>
        {isAuthenticated &&  <Header history={history} /> }
        <Switch>
          <Route path="/loggedout">
              <Center h="100vh" w="100vw">
                <Box padding="24px">
                  You've been logged out!
                </Box>
              </Center>
          </Route>
          <ProtectedRoute path="/">
            <BaseTemplate/>
          </ProtectedRoute>
        </Switch>
      </QueryParamProvider>
      </QueryClientProvider>
    </Router>
  )
}

const ProtectedRoute = (props) =>  {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const routeIsAuthenticated = isAuthenticated
  useEffect(() => {
    if (isLoading || routeIsAuthenticated) {
      return;
    }
    (async ()=> {
      await loginWithRedirect();
    })();
  }, [
    isLoading,
    routeIsAuthenticated,
    loginWithRedirect,
  ]);

  return  (
    <Route path={props.path}>
      {routeIsAuthenticated ? props.children : 
      <Center w="100%" h="100%" bg="brand.100" flexDirection="column"> 
        <Spinner />
        <Box color="white"> Loading... </Box>
      </Center>}
    </Route>
  )
};

/*
  context.accessToken[namespace + ‘authorities’] = user.app_metadata.roles;
  context.accessToken[namespace + ‘locale’] = user.app_metadata.itc_locale.toUpperCase();
  context.accessToken[namespace + ‘user_name’] = user.user_name;

{
  "name": "jdoe@foobar.com",
  "email": "jdoe@foobar.com",
  "user_id": "auth0|0123456789",
  "nickname": "jdoe",
  "picture": "http://foobar.com/pictures/jdoe.png",
  "identities": [
    {
      "provider": "auth0",
      "user_id": "0123456789",
      "connection": "Username-Password-Connection",
      "isSocial": false
    }
  ],
  "app_metadata": {
    "explorer": "AZ",
    "field": ""
  }
}

*/