import { ArrowForwardIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, List, ListIcon, ListItem, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useQueryParams } from 'use-query-params';
// const defaultData = ['Race', 'Urbanicity', 'Testsatsa', 'Tesats', 'A', 'B']

const Sidebar = (props) => {
    const [query, setQuery] = useQueryParams(props.params);
    const data = props.data

    const isExplorer = 'etype' in query
    const selectedId = isExplorer ? query.category+query.etype : query.category
    return (
        <Flex w={props.width ? props.width : "200px"} bg="brand.400" overflowY="auto" height="100%" flex="0 0 auto" fontFamily="Texta Alt Heavy" color="white">
                <Box padding="20px">
                    <List spacing={3}>
                    {!!data && Object.keys(data).reverse().map( (c) => {

                            return(<Box key={c}>
                                <ListItem letterSpacing=".1em" textTransform="uppercase"> {c} </ListItem>
                                {!!data && data[c].map(d=>{
                                    if(d.type !== 'dropdown') {
                                        return (<ListItem  
                                                    key={d.text}
                                                    letterSpacing=".1em" 
                                                    marginLeft="0px" 
                                                    marginRight="32px"
                                                    mt="4"
                                                    fontSize="14px"
                                                    as="button"
                                                    textTransform="none"
                                                    display="flex"
                                                    onClick={()=>setQuery(d.queryPayload)}
                                                >
                                            <Box>
                                                {(selectedId===d.id) ? <ListIcon as={ArrowForwardIcon} color="brand.300" /> : 
                                                                                                    <ListIcon as={ArrowForwardIcon} color="brand.400" />} 
                                            </Box>
                                            <Box textAlign="left"> 
                                                {d.text} 
                                            </Box>
                                        </ListItem>)
                                    } else {
                                        return (
                                                <Menu
                                                key={d.text} 
                                                offset={[150,-20]}>
                                                    <MenuButton 
                                                    letterSpacing=".1em" 
                                                    fontSize="14px"
                                                    as='button'
                                                    display="flex"
                                                    bg="none"
                                                    >
                                                        <Box display="flex" flexDirection="row"mt="4" >
                                                            <Box>
                                                                {((query.category.indexOf(' X ') !== -1) && (isExplorer ? query.etype === d.cat : true)) ? <ListIcon as={ArrowForwardIcon} color="brand.300" /> : 
                                                                                                                    <ListIcon as={ArrowForwardIcon} color="brand.400" />} 
                                                            </Box>
                                                            <Box 
                                                            textAlign="left" textTransform="none"
                                                            letterSpacing=".1em" 
                                                            fontSize="14px"
                                                            > 
                                                                {d.text} 
                                                                <ChevronDownIcon marginLeft="8px" fontSize="18px" />
                                                            </Box>
                                                        </Box>
                                                    </MenuButton>
                                                    <MenuList bg="brand.200" padding="0px" zIndex={1000} maxHeight="300px" overflowY="auto" border="0px" borderRadius="0px">
                                                        {d.items.map(i=>{
                                                            return(
                                                                <MenuItem key={i.id} onClick={()=>{setQuery(i.queryPayload)}}>
                                                                    {i.text}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </MenuList>
                                            </Menu>
                                        );
                                    } 
                                })}
                                </Box>)
                    })
                }
                    </List>
                </Box>
                
        </Flex>
    )
}
export default Sidebar
/*
                        <ListItem letterSpacing=".1em" textTransform="uppercase"> Two-Way </ListItem> 
                        <ListItem
                            letterSpacing=".1em" 
                            marginLeft="0px" 
                            marginRight="32px"
                            fontSize="14px"
                            as="button"
                            textTransform="none"
                            display="flex">
                            <Box>
                            {query.category==='comparator' ?  <ListIcon as={ArrowForwardIcon} color="brand.300" /> : <ListIcon as={ArrowForwardIcon} color="brand.400" />} 
                            </Box>
                            <Box textAlign="left" onClick={()=>history.push('/comparator')}> Comparator </Box>   
                        </ListItem>
*/