/// app.js
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Flex } from '@chakra-ui/react';
import axios from "axios";
import React from 'react';
import {
  useQuery
} from "react-query";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import tinycolor from 'tinycolor2';
import {
  StringParam, useQueryParams
} from 'use-query-params';
import { formatPercent } from '../utils';

const chartColors = ['#005868', '#fff2d1','#ff6d2d','#bd210a','#ffb8bf', '#ffdc1e','#fff2d1','#f0eded']
const DATA_URL = process.env.REACT_APP_BACKEND_API_URL+'/data/explorer/graph'
const audience = process.env.REACT_APP_BACKEND_API_AUDIENCE;


function useGraphData(category,cycle,state,county,etype, mapgeo) {
  const scope = 'read:explorer';
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["graphData", category, cycle, county,state,etype,mapgeo], async () => {
      const accessToken = await getAccessTokenSilently({ audience, scope });
      const requestObj = {
          method:'get',
          url: DATA_URL,
          headers: {'Authorization': `Bearer ${accessToken}`},
          params: {category,cycle,state,county,etype,mapgeo}
      }
      const { data } = await axios(requestObj);
      console.log(data)
      return data;
    }, {
    enabled: (!!category&&!!cycle&&!!state&&!!etype),
  });
}


// TODO LIGHTEN UP THE COLORS
function Graph(props) {
  const [query] = useQueryParams({
    county: StringParam,
    category: StringParam,
    state: StringParam,
    cycle: StringParam,
    etype: StringParam,
    mapgeo: StringParam
  });
 const CustomizedLabel = (t) => {
    return (
      <text 
      x={t.x + t.width / 2} 
      y={t.y + 15} 
      fill={tinycolor(t.fill).getBrightness() > 128 ? 'black' : 'white'}
      textAnchor="middle"> {formatPercent(t.value)} </text>
    );
  }
  const { category, county, state, cycle,etype,mapgeo } = query;
  const {data} = useGraphData(category,cycle,state,county,etype, mapgeo);

  return (
    <Flex color="black" minWidth="100px" minHeight="300px" flex="1" paddingTop="6px" flexDirection="column">
      <Box paddingLeft="80px" fontFamily="Texta Alt Heavy" fontSize="16px" fontWeight="500" textTransform="uppercase" letterSpacing="0.1em"> Turnout Rates by Category </Box>
      <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={300}
            height={300}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            data={data?.graphData}
          >
            <XAxis dataKey="cat_val" allowDuplicatedCategory={false}/>
            <YAxis dataKey="val" tickFormatter={(val) => {return Math.round((val*100)).toString()+'%'}} />

            <Legend payload={[{ value: 'Average Turnout Rate', type: 'line', color: '#0967b0'}]} iconType="plainline"/>
            <CartesianGrid vertical={false} />
            {!!data && <ReferenceLine y={!!data && 'refLineData' in data ? data?.refLineData[0]?.val : 0} isFront={1} stroke="#0967b0" strokeWidth={3}> 
              </ReferenceLine>}
            {!!data && <Bar dataKey="val" name={'test'} label={<CustomizedLabel />}>
              {!!data && data?.graphData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={chartColors[index]} />
              ))}
            </Bar>}

              
          </BarChart>
        </ResponsiveContainer>
    </Flex>
  );
}

export default Graph; 