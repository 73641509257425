import { Box, Flex, FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import QueryMenu from '../../base/components/QueryMenu.js';
import QueryRangeSlider from '../../base/components/QueryRangeSlider.js';
import { genMenuItems } from "../utils.js";
const STATES_DEFAULT = ['AZ','NH','NV','PA','WI','OH', 'FL', 'NC','GA'].sort()
const COUNTIES_DEFAULT = ['Maricopa','Pima','Santa Cruz'].sort()
const UNIVERSES_DEFAULT = [
    {text: 'Turnout', id: 'generic', payload: {universe: 'generic', support: [60, 100], turnout: [30,70]}}, 
    {text: 'Nonpartisan Mail', id:'npam', payload: {universe: 'npam', support: [60,100], turnout: [5,80]}},
    {text: 'VAP', id:'vap', payload: {universe: 'vap'}},
    {text: 'Movers', id:'movers', payload: {universe: 'movers', support: [60,100]}},
    {text: 'New Registrants', id:'newreg', payload: {universe: 'newreg'}},
    {text: 'First Time Voters', id:'ftv', payload: {universe: 'ftv', support: [60,100], turnout: [5,100]}}
]
const UniverseControlPanel= (props) => {
    const states = props.states ?? STATES_DEFAULT
    const counties = props.counties ?? COUNTIES_DEFAULT
    const universes = props.universes ?? UNIVERSES_DEFAULT
    return (
        <Flex p="4" flexDirection="column" marginRight="175px">
            <FormControl>
                <FormLabel htmlFor='email' color="black">Geography</FormLabel>
                <Flex>
                <QueryMenu val_key='county' data={[{'text': 'All Counties', id: 'acss', payload: {county: undefined}}].concat(genMenuItems(counties, 'county'))} params={props.params} default_val="ALL COUNTIES"/>
                <QueryMenu val_key='state' data={genMenuItems(states, 'state', {'county': undefined})} params={props.params}/>
                </Flex>
                <FormHelperText>The geography to pull counts from.</FormHelperText>
            </FormControl>
            <Box>
            <FormControl>
                <FormLabel htmlFor='email' color="black">Universe</FormLabel>
                <QueryMenu val_key='universe' data={genMenuItems(universes,'universe')} params={props.params}/>
                <FormHelperText>The universe to pull counts from.</FormHelperText>
            </FormControl>
            <FormControl>
                <FormLabel htmlFor='email' color="black">Support</FormLabel>
                <QueryRangeSlider steps={5} minStepsBetweenThumbs={1} key_val="support" params={props.params}/>
                <FormHelperText>The maximum and minimum support values.</FormHelperText>
            </FormControl>
            <FormControl>
                <FormLabel htmlFor='email' color="black">Turnout</FormLabel>
                <QueryRangeSlider steps={5} minStepsBetweenThumbs={1} key_val="turnout" params={props.params}/>
                <FormHelperText>The maximum and minimum turnout values.</FormHelperText>
            </FormControl>
            </Box>
        </Flex>
    )
}


export default UniverseControlPanel
/*
            <Select
            color="white" 
            bg="brand.300" 
            borderRadius="0px" 
            fontSize= "14px" 
            lineHeight= "2.8em" 
            value={query.county}
            onChange={t=>setQuery({county:t.target.value != 'all' ? t.target.value : undefined})}
            >
                <option value="all"> County: All </option>
            </Select>
*/
