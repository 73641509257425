import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Center, Flex, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList } from "@chakra-ui/react";
import { StringParam, useQueryParams } from 'use-query-params';

const CYCLES_DEFAULT = ['16','18','20']
const CTYS_DEFAULT = ['Maricopa']
const DMAS_DEFAULT = ['Phoenix']
const STATES_DEFAULT = ['GA','AZ']
const CountySelect = (props) => {
    const [query, setQuery] = useQueryParams({
        county: StringParam,
        cycle: StringParam,
        state: StringParam,
        etype: StringParam,
        mapgeo: StringParam
    });
    const cycles = (query.state !== 'US' ? props.cycles : ['22']) ?? CYCLES_DEFAULT
    const counties = props.counties ?? CTYS_DEFAULT
    const dmas = props.dmas ?? DMAS_DEFAULT
    const states = (query.state !== 'US' ? props.states : ['US']) ?? STATES_DEFAULT
    const etype = query.etype ?? 'historic'

    return (
     <Flex marginTop="12px" flexDirection="row"  flexShrink="0" height="min-content">
            <Center color="black" paddingLeft="12px" paddingRight="12px" fontFamily="Texta Alt Bold" letterSpacing=".01em" color="brand.100"> Last Updated: {new Date(props.lastupdated).toLocaleDateString('en-US') === 'Invalid Date' ? '...' : new Date(props.lastupdated).toLocaleDateString('en-US')} </Center>
            <Flex boxShadow="0px 4px 6px 0px rgb(0,0,0,20%)">
            <Menu  bg="brand.300"  color="white" border="0px" >
                <MenuButton as={Button}  bg="brand.300" rightIcon={<ChevronDownIcon />} variant="solid">
                    {'20'+query.cycle+" CYCLE"}
                </MenuButton>
                <MenuList bg="brand.300" padding="0px" overflowY="auto" maxHeight="300px" >
                {cycles.map( (c) => {
                    if (etype !== 'historic'){
                        return ( 
                            <MenuItem key={c} value={c} _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" onClick={()=>{setQuery({cycle: c})}}> 
                            20{c} Cycle
                            </MenuItem>
                        )
                    } else {
                            return ( 
                                <MenuItem key={c} value={c} _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" onClick={()=>{setQuery({cycle: c})}}> 
                                20{c} Cycle
                                </MenuItem>
                            )
                    }
                })}
                </MenuList>
            </Menu>
            <Center bg="brand.300" paddingLeft="8px" paddingRight="8px" fontStyle="italic"> in </Center>
            {query.state !== 'US' && <Menu  bg="brand.300"  color="white" border="0px">
                <MenuButton as={Button}  bg="brand.300" rightIcon={<ChevronDownIcon />} variant="solid">
                    {query.county ? query.county : 'All Counties'}
                </MenuButton>
                <MenuList bg="brand.300" padding="0px" maxHeight="300px" overflowY="auto">
                <MenuGroup title='DMA'>
                <MenuItem _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" onClick={()=>{setQuery({county: undefined})}}> 
                    All DMAs
                </MenuItem>
                {dmas?.map( (c) => {
                    return ( 
                        <MenuItem key={c} value={c} _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" onClick={()=>{setQuery({county: c, mapgeo: 'DMA'})}}> 
                        {c}
                        </MenuItem>
                    )
                })}
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title='COUNTY'>
                <MenuItem _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" onClick={()=>{setQuery({county: undefined})}}> 
                    All Counties
                </MenuItem>
                {counties?.map( (c) => {
                    return ( 
                        <MenuItem key={c} value={c} _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" onClick={()=>{setQuery({county: c, mapgeo: undefined})}}> 
                        {c}
                        </MenuItem>
                    )
                })}
                </MenuGroup>
                </MenuList>
            </Menu> }

        <Menu  bg="brand.300"  color="white" border="0px">
                <MenuButton as={Button}  bg="brand.300" rightIcon={<ChevronDownIcon />} variant="solid">
                    {query.state}
                </MenuButton>
                <MenuList bg="brand.300" padding="0px" maxHeight="300px" overflowY="auto">
                {states.map( (c) => {
                    return ( 
                        <MenuItem key={c} value={c} _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" onClick={()=>{setQuery({state: c,county: undefined})}}> 
                        {c}
                        </MenuItem>
                    )
                })}
                </MenuList>
            </Menu>
            </Flex>
    </Flex>
    )
}

export default CountySelect
/*
            <Select
            color="white" 
            bg="brand.300" 
            borderRadius="0px" 
            fontSize= "14px" 
            lineHeight= "2.8em" 
            value={query.county}
            onChange={t=>setQuery({county:t.target.value != 'all' ? t.target.value : undefined})}
            >
                <option value="all"> County: All </option>
            </Select>
*/
