
import React from 'react';
import ReactDOM from 'react-dom';
import App from './base/App';
import './base/assets/index.css';
import reportWebVitals from './base/assets/reportWebVitals';
ReactDOM.render(
  <React.StrictMode>

            <App />

  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();