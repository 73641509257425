import { useAuth0 } from '@auth0/auth0-react';
import { Box, Center, Flex, Heading, Spacer } from "@chakra-ui/react";
import axios from "axios";
import { useQuery } from "react-query";
import {
    encodeQueryParams
} from 'serialize-query-params';
import { DateParam, StringParam, useQueryParams } from 'use-query-params';
import DateRangeSelector from '../base/components/DateRangeSelector.js';
import QueryMenu from '../base/components/QueryMenu.js';
import Sidebar from '../base/components/Sidebar.js';
import HaydenTable from "../base/components/Table.js";
import { getTooltipText } from "./tooltips.js";
import { genColumns, genMenuItems } from './utils.js';

const sidebarRows = {
    'CATEGORY': [
        { 'id':'Station', 'text':'Station', 'cat':'category', queryPayload: {category: 'Station'} },
        { 'id':'Topic', 'text':'Topic', 'cat':'category', queryPayload: {category: 'Topic'} },
        { 'id':'Day Part', 'text':'Day Part', 'cat':'category', queryPayload: {category: 'Day Part'} },
        { 'id':'Weekday', 'text':'Weekday', 'cat':'category', queryPayload: {category: 'Weekday'} },
        { 'id':'Advertiser Type', 'text':'Advertiser Type', 'cat':'category', queryPayload: {category: 'Advertiser Type'}}, 
        { 'id':'Tone', 'text':'Tone', 'cat':'category', queryPayload: {category: 'Tone'}}
    ],
}

const queryParams = {
    state: StringParam,
    county: StringParam,
    category: StringParam,
    election: StringParam,
    dma: StringParam,
    startdate: DateParam,
    enddate: DateParam
}

const DATA_URL = process.env.REACT_APP_BACKEND_API_URL+'/tv/tv_explorer/table'
const audience = process.env.REACT_APP_BACKEND_API_AUDIENCE;
const scope = 'read:explorer';

function useTableData(category,state,election, dma,startdate,enddate) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["tvTableData", category,state,dma, election,startdate,enddate], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: DATA_URL,
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: encodeQueryParams(
                queryParams,
                {category,state,dma, election,startdate,enddate}
            )
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) {
            errorMessage = err.response.status;
        }
        if(errorMessage===403){ throw 403 } else { throw 404 }
      }, {
        retry: 0,
      enabled: (!!category&&!!state&&!!election&&!!startdate&&!!enddate),
    });
}


function useUniExplorerUI(state) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["tvExpUI",state], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: process.env.REACT_APP_BACKEND_API_URL+'/tv/tv_explorer/ui',
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: {part: 'main',state}
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) { errorMessage = err.response.status; }
        console.log(errorMessage)
        if(errorMessage===403){
            console.log('error?')
            throw new Error('Oh no!')
        }
      }, {
        enabled: (!!state)
      });
}

const STATES_DEFAULT = ['AZ','NH','NV','PA','WI','OH', 'FL', 'NC','GA'].sort()
const ELECTIONS_DEFAULT = ['US Presidential 2020 General', 'AZ Senate 2018 General']
const DMAS_DEFAULT = ['Phoenix, AZ', 'Tucson, AZ', 'Yuma, AZ-El Centro, CA', 'Albuquerque-Santa Fe, NM']

var TVExplorer = function (props) {
    const [query] = useQueryParams(queryParams);
    const { category, state, dma, election, startdate, enddate } = query;
    const { data, isFetching } = useTableData(category,state, election, dma, startdate, enddate);
    const { data: uidata } = useUniExplorerUI(state); // eslint-disable-line

    console.log(uidata)

    const states = STATES_DEFAULT
    const elections = uidata?.elections ?? ELECTIONS_DEFAULT 
    const dmas = uidata?.dmas ?? DMAS_DEFAULT 

    return (
        <Flex height="100%" overflow="hidden">
            <Sidebar isLoading={false} data={sidebarRows} status={false} params={queryParams} />

            <Flex  flexDirection="column" w="100%" overflowY="scroll">
                <Flex>
                    <Flex paddingLeft="16px" flexDirection="column">
                        <Heading color="brand.100" fontSize="44px" fontWeight="500" fontFamily="Texta Alt Bold">
                            TV Spending by {query.category}
                        </Heading>
                    </Flex>
                    <Spacer />
                    <Flex marginTop="12px" flexDirection="row"  flexShrink="0" height="min-content">
                        <Flex boxShadow="0px 4px 6px 0px rgb(0,0,0,20%)" bg="brand.300">
                            <DateRangeSelector params={queryParams} />
                            <QueryMenu val_key='election' data={genMenuItems(elections, 'election', {'county': undefined})} params={queryParams}/>
                            <Center bg="brand.300" paddingLeft="8px" paddingRight="8px" fontStyle="italic" color="white"> in </Center> 
                            <QueryMenu val_key='dma' data={[{'text': 'All Dmas', id: 'acss', payload: {dma: undefined}}].concat(genMenuItems(dmas, 'dma', {'county': undefined}))} params={queryParams} default_val="All DMAs"/>
                            <QueryMenu val_key='state' data={genMenuItems(states, 'state', {'county': undefined, election: 'US Presidential 2020 General', startdate: new Date('2019-01-02'), enddate: new Date('2020-11-03')})} params={queryParams}/>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex flexDirection="row" h="800px" marginLeft="auto" marginRight="auto">
                    <Box
                    paddingTop="20px"
                    flexDirection="column"
                    overflowY="auto"
                    color="black"
                    overflowX="hidden"
                    >
                    <Center> Hover over the columns to see their definitions.   Numbers <br /> are estimated and will fall within 1% of true counts. </Center>
                    <HaydenTable tableData={data} columns={genColumns(category)} loading={isFetching | !category | !state | !election} state={state} etype={'modeled'} getTooltipText={getTooltipText} columnSize={4} />
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    )
}



export default TVExplorer

/*
 <ExplorerTable tableData={data} columns={genColumns(category,etype,state)} loading={isFetching} state={state} etype={etype} getTooltipText={getTooltipText}/> 
*/