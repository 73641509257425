import { Box, Flex, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useQueryParams } from 'use-query-params';
// const defaultData = ['Race', 'Urbanicity', 'Testsatsa', 'Tesats', 'A', 'B']

const QueryRangeSlider = (props) => {
    const [query, setQuery] = useQueryParams(props.params); //eslint-disable-line
    const [bounds, setBounds] = useState(query[props.key_val])

    useEffect(()=>{
        setBounds(query[props.key_val])
    },[query])

    return (
        <Flex flexDirection="column">
            <Box>
            <RangeSlider 
            aria-label={"['min', 'max']"}
            defaultValue={query[props.key_val]}
            onChangeEnd={(val) => {
                let payload = {}
                payload[props.key_val] = val
                setQuery(payload)
            }}
            onChange={(val)=>{ setBounds(val) }}
            step={props.steps}
            minStepsBetweenThumbs={props.minStepsBetweenThumbs}
            value={bounds}
            >
                <RangeSliderTrack bg="brand.300">
                    <RangeSliderFilledTrack bg="brand.100"/>
                </RangeSliderTrack>
                <RangeSliderThumb index={0} boxSize={6} bg="brand.200">
                    <Box color="white">
                        {bounds[0]}
                    </Box>
                </RangeSliderThumb>
                <RangeSliderThumb index={1} boxSize={6} bg="brand.200">
                    <Box color="white">
                        {bounds[1]}
                    </Box>
                </RangeSliderThumb>
            </RangeSlider>
            </Box>
            <Flex>
            </Flex>
        </Flex>
    )
}
export default QueryRangeSlider



