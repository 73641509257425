
var middleGreen = "#329F5B";
var lightGreen = "#8FD5A6";
var lightOrange = "#ffefe8";

export const turnoutColorDefinitions = {
    '#ffb8bf': "GOP Voter",
    '#f0eded': "Don't talk to.",
    '#fff2d1': 'Persuasion and Turnout Tier 1',
    '#fce39e': 'Persuasion and Turnout Tier 2',
    [lightOrange]: 'Persuasion and Turnout Tier 3',
    [middleGreen]: 'Highest priority targets',
    [lightGreen]: 'Support expansion'
}

export const supportColorDefinitions = {
    '#ffb8bf': "GOP Voter",
    '#f0eded': "Don't talk to.",
    '#fce39e': 'Persuasion and Turnout Tier 2',
    '#fff2d1': 'Persuasion and Turnout Tier 1',
    [lightOrange]: 'Persuasion and Turnout Tier 3',
    [middleGreen]: 'Highest priority targets',
    [lightGreen]: 'Support expansion'
}

export const persuasionColorDefinitions = {
    '#ffb8bf': "GOP Voter",
    '#f0eded': "Don't talk to.",
    '#fce39e': 'Persuasion and Turnout Tier 2',
    '#fff2d1': 'Persuasion and Turnout Tier 1',
    [lightOrange]: 'Persuasion and Turnout Tier 3',
    [middleGreen]: 'Highest priority targets',
    [lightGreen]: 'Support expansion'
}


export const scoreDistColorDefs = {
    'turnout': turnoutColorDefinitions,
    'support': supportColorDefinitions,
    'persuasion': persuasionColorDefinitions
}

export const turnoutColorMap = {
    '0-9': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '10-19': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '20-29': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '30-39': {
        '0-9': "#f0eded",
        '10-19': "#f0eded",
        '20-29': "#f0eded",
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": "#fff2d1",
        "80-89": "#fff2d1",
        "90+": "#fff2d1"
    }, 
    '40-49': {
        '0-9': "#f0eded",
        '10-19': "#f0eded",
        '20-29': "#f0eded",
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": "#fff2d1",
        "80-89": "#fff2d1",
        "90+": "#fff2d1"
    }, 
    '50-59': {
        '0-9': lightOrange,
        '10-19': lightOrange,
        '20-29': lightOrange,
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": "#fff2d1",
        "80-89": "#fff2d1",
        "90+": "#fff2d1"
    }, 
    '60-69': {
        '0-9': lightOrange,
        '10-19': lightOrange,
        '20-29': lightOrange,
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": "#fff2d1",
        "80-89": "#fff2d1",
        "90+": "#fff2d1"
    }, 
    '70-79': {
        '0-9': "#fce39e",
        '10-19': "#fce39e",
        '20-29': lightGreen,
        '30-39': middleGreen,
        "40-49": middleGreen,
        "50-59": middleGreen,
        "60-69": middleGreen,
        "70-79": lightGreen,
        "80-89": "#fce39e",
        "90+": "#fce39e"
    }, // #10FFCB
    '80-89': {
        '0-9': "#fce39e",
        '10-19': "#fce39e",
        '20-29': lightGreen,
        '30-39': middleGreen,
        "40-49": middleGreen,
        "50-59": middleGreen,
        "60-69": middleGreen,
        "70-79": lightGreen,
        "80-89": "#fce39e",
        "90+": "#fce39e"
    }, // #10FFCB
    '90+': {
        '0-9': "#fce39e",
        '10-19': "#fce39e",
        '20-29': lightGreen,
        '30-39': middleGreen,
        "40-49": middleGreen,
        "50-59": middleGreen,
        "60-69": middleGreen,
        "70-79": lightGreen,
        "80-89": "#fce39e",
        "90+": "#fce39e"
    }, // #10FFCB
}

export const supportColorMap = {
    '0-9': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '10-19': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '20-29': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '30-39': {
        '0-9': "#f0eded",
        '10-19': "#f0eded",
        '20-29': "#f0eded",
        '30-39': "#fff2d1",
        "40-49": "#fff2d1",
        "50-59": "#fff2d1",
        "60-69": "#fff2d1",
        "70-79": "#fce39e",
        "80-89": "#fce39e",
        "90+": "#fce39e"
    }, 
    '40-49': {
        '0-9': "#f0eded",
        '10-19': "#f0eded",
        '20-29': "#f0eded",
        '30-39': "#fff2d1",
        "40-49": "#fff2d1",
        "50-59": "#fff2d1",
        "60-69": "#fff2d1",
        "70-79": "#fce39e",
        "80-89": "#fce39e",
        "90+": "#fce39e"
    }, 
    '50-59': {
        '0-9': lightOrange,
        '10-19': lightOrange,
        '20-29': lightOrange,
        '30-39': "#fff2d1",
        "40-49": "#fff2d1",
        "50-59": "#fff2d1",
        "60-69": "#fff2d1",
        "70-79": "#fce39e",
        "80-89": "#fce39e",
        "90+": "#fce39e"
    },
    '60-69': {
        '0-9': lightOrange,
        '10-19': lightOrange,
        '20-29': lightOrange,
        '30-39': "#fff2d1",
        "40-49": "#fff2d1",
        "50-59": "#fff2d1",
        "60-69": "#fff2d1",
        "70-79": "#fce39e",
        "80-89": "#fce39e",
        "90+": "#fce39e"
    },
    '70-79': {
        '0-9': "#fff2d1",
        '10-19': "#fff2d1",
        '20-29': "#fff2d1",
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": lightGreen,
        "80-89":  lightGreen,
        "90+":  lightGreen,
    }, // #10FFCB
    '80-89': {
        '0-9': "#fff2d1",
        '10-19': "#fff2d1",
        '20-29': "#fff2d1",
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": lightGreen,
        "80-89":  middleGreen,
        "90+":  middleGreen,
    }, // #10FFCB
    '90+': {
        '0-9': "#fff2d1",
        '10-19': "#fff2d1",
        '20-29': "#fff2d1",
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": lightGreen,
        "80-89":  middleGreen,
        "90+":  middleGreen,
    }, // #10FFCB
}

export const persuasionColorMap = {
    '0-9': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '10-19': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '20-29': {
        '0-9': "#ffb8bf",
        '10-19': "#ffb8bf",
        '20-29': "#ffb8bf",
        '30-39': "#ffb8bf",
        "40-49": "#ffb8bf",
        "50-59": "#ffb8bf",
        "60-69": "#ffb8bf",
        "70-79": "#ffb8bf",
        "80-89": "#ffb8bf",
        "90+": "#ffb8bf"
    }, 
    '30-39': {
        '0-9': "#f0eded",
        '10-19': "#f0eded",
        '20-29': "#f0eded",
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": "#fff2d1",
        "80-89": "#fff2d1",
        "90+": "#fff2d1"
    }, 
    '40-49': {
        '0-9': "#f0eded",
        '10-19': "#f0eded",
        '20-29': "#f0eded",
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": "#fff2d1",
        "80-89": "#fff2d1",
        "90+": "#fff2d1"
    }, 
    '50-59': {
        '0-9': lightOrange,
        '10-19': lightOrange,
        '20-29': lightOrange,
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": "#fff2d1",
        "80-89": "#fff2d1",
        "90+": "#fff2d1"
    }, 
    '60-69': {
        '0-9': lightOrange,
        '10-19': lightOrange,
        '20-29': lightOrange,
        '30-39': "#fce39e",
        "40-49": "#fce39e",
        "50-59": "#fce39e",
        "60-69": "#fce39e",
        "70-79": "#fff2d1",
        "80-89": "#fff2d1",
        "90+": "#fff2d1"
    }, 
    '70-79': {
        '0-9': "#fce39e",
        '10-19': "#fce39e",
        '20-29': lightGreen,
        '30-39': middleGreen,
        "40-49": middleGreen,
        "50-59": middleGreen,
        "60-69": middleGreen,
        "70-79": lightGreen,
        "80-89": "#fce39e",
        "90+": "#fce39e"
    }, // #10FFCB
    '80-89': {
        '0-9': "#fce39e",
        '10-19': "#fce39e",
        '20-29': lightGreen,
        '30-39': middleGreen,
        "40-49": middleGreen,
        "50-59": middleGreen,
        "60-69": middleGreen,
        "70-79": lightGreen,
        "80-89": "#fce39e",
        "90+": "#fce39e"
    }, // #10FFCB
    '90+': {
        '0-9': "#fce39e",
        '10-19': "#fce39e",
        '20-29': lightGreen,
        '30-39': middleGreen,
        "40-49": middleGreen,
        "50-59": middleGreen,
        "60-69": middleGreen,
        "70-79": lightGreen,
        "80-89": "#fce39e",
        "90+": "#fce39e"
    }, // #10FFCB
}

export const scoreDistColorMaps = {
    'turnout': turnoutColorMap,
    'support': supportColorMap,
    'persuasion': persuasionColorMap
}