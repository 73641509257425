import { useAuth0 } from '@auth0/auth0-react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Center, Flex, Heading, ListItem, Spacer, UnorderedList } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from 'react';
import {
    useQuery
} from "react-query";
import { useLocation } from "react-router-dom";
import {
    StringParam, useQueryParams
} from 'use-query-params';
import Sidebar from '../base/components/Sidebar.js';
import CountySelect from "./components/CountySelect.js";
import Graph from "./components/Graph.js";
import Map from './components/Map.js';
import ExplorerTable from './components/Table.js';
import { getTooltipText } from "./tooltips.js";
import { genColumns, genSidebarRows } from './utils.js';

const DATA_URL = process.env.REACT_APP_BACKEND_API_URL+'/data/explorer/table'
const audience = process.env.REACT_APP_BACKEND_API_AUDIENCE;
const scope = 'read:explorer';

function useTableData(category,cycle,state,county,etype, mapgeo) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["tableData", category, cycle, county,state,etype, mapgeo], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: DATA_URL,
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: {category,cycle,state,county,etype, mapgeo}
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) {
            errorMessage = err.response.status;

        }
        if(errorMessage===403){
            throw 403
        } else {
            throw 404
        }
      }, {
        retry: 0,
      enabled: (!!category&&!!cycle&&!!state&&!!etype),
    });
}

function useExplorerUI(category,state,etype) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["explorerUI",state,etype], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: process.env.REACT_APP_BACKEND_API_URL+'/ui/explorer',
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: {part: 'main',state,etype}
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) {
            errorMessage = err.response.status;

        }
        if(errorMessage===403){
            console.log('error?')
            throw new Error('Oh no!')
        }

      }, {
        enabled: (!!category, !!etype)
      });
}

function useMapData(category, cycle, state, county, mapsub, mapval, etype, mapgeo) {
    const scope = "read:explorer";
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
      ["mapData", category, cycle, mapsub, mapval, state, etype, mapgeo],
      async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
          method: "get",
          url: process.env.REACT_APP_BACKEND_API_URL + "/data/explorer/map",
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { category, cycle, state, mapsub, mapval, etype, mapgeo },
        };
        const { data } = await axios(requestObj);
        return data;
      },
      {
        enabled: !!category && !!cycle && !!state && !!etype,
      }
    );
  }
  
  function useMapUI(state,category,cycle,etype) {
    const audience = process.env.REACT_APP_BACKEND_API_AUDIENCE;
    const scope = 'read:explorer';
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["explorerMapUI", state,category, cycle,etype], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: process.env.REACT_APP_BACKEND_API_URL+'/ui/explorer',
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: {state,category, cycle, etype, part: 'map'}
        }
        const { data } = await axios(requestObj);
        return data;
      }, {
        enabled: (!!category&&!!state&&!!cycle&&!!etype)
      });
  }
  


const queryParams = {
    category: StringParam,
    mapsub: StringParam,
    mapval: StringParam,
    mapgeo: StringParam,
    etype:StringParam,
    cycle: StringParam,
    state: StringParam,
    county: StringParam
}

const primaryDocumentationDict = {
    'AZ': {
        title: 'Arizona has an open to unaffiliated voters primary:',
        description: 'Registration counts for the primary include both Independents and voters of the party selected.'
    },
    'CO': {
        title: 'Colorado has an open to unaffiliated voters primary:',
        description: 'Registration counts for the primary include both Independents and voters of the party selected.'
    },
    'NH': {
        title: 'New Hampshire has an open to unaffiliated voters primary:',
        description: 'Registration counts for the primary include both Independents and voters of the party selected.'
    },
    'WI': {
        title: 'Wisconsin has open primaries:',
        description: 'Registration counts for the primary include all registered voters eligible to vote at the time the primary was held. Wisconsin is not a party registration state, therefore we assigned participation in each primary according to voters Democratic party score.'
    },
    'GA': {
        title: 'Georgia has open primaries:',
        description: 'Registration counts for the primary include all registered voters eligible to vote at the time the primary was held.'
    },
    'OH': {
        title: 'Ohio has an open to unaffiliated voters primary:',
        description: 'Registration counts for the primary include all registered voters eligible to vote at the time the primary was held.'
    },
    'NC': {
        title: 'North Carolina effectively has closed primaries:',
        description: 'Registration counts for the primary include only voters registered to the selected party eligible to vote at the time the primary was held.'
    },
    'FL': {
        title: 'Florida has closed primaries:',
        description: 'Registration counts for the primary include only voters registered to the selected party eligible to vote at the time the primary was held.'
    },
    'MD': {
        title: 'Maryland has closed primaries:',
        description: 'Registration counts for the primary include only voters registered to the selected party eligible to vote at the time the primary was held.'
    },
    'NV': {
        title: 'Nevada has closed primaries:',
        description: 'Registration counts for the primary include only voters registered to the selected party eligible to vote at the time the primary was held.'
    },
    'PA': {
        title: 'Pennsylvania has closed primaries:',
        description: 'Registration counts for the primary include only voters registered to the selected party eligible to vote at the time the primary was held.'
    }
}


var Explorer = function (props) {
    let location = useLocation();
    const [query, setQuery] = useQueryParams(queryParams);
    const { category, county, state, cycle,etype, mapsub, mapval, mapgeo } = query;
    const { data, error, isFetching, isError } = useTableData(category,cycle,state,county,etype,mapgeo);
    const ui = useExplorerUI(category,state,etype);
    const [sidebarRows, setSidebarRows] = useState(undefined)
    const [twowayRows, setTwowayRows] = useState(undefined)
    const mapUI = useMapUI(state,category, cycle,etype);
    const {data: mapData} = useMapData(category,cycle,state,county,mapsub,mapval,etype, mapgeo);
    useEffect(()=>{
        if(!sidebarRows & ui.status!=='loading'){
            setSidebarRows(ui.data?.sidebar)
            setTwowayRows(ui.data?.twowayrows)
        }
    },[ui,query]) //eslint-disable-line

    useEffect(()=>{
        if (location.pathname==='/explorer'){
            if (!category | !cycle | !etype){
                setQuery({etype:'historic', category: 'Race', cycle: '20'})
            }
        }
    }, [location, category, cycle, state, etype, setQuery])

    useEffect(()=>{
        if (location.pathname==='/explorer'){
            if ( !state ){
                setQuery({state: ui?.data?.stateselect[0], cycle: '20', etype: etype})
            }
        }
    }, [ui, location.pathname, state, setQuery, etype])

    if(isError && error!==403){ // TODO Congrats you broke Explorer.
        return (            
        <Center w="100%" h="100%" bg="brand.100"> 
            <Center padding="12px" letterSpacing="0.1em" fontFamily="Texta Alt Heavy" color="white">
                Congratulations, you found a bug! Please report this to analytics.
                Click <a href="https://docs.google.com/forms/d/e/1FAIpQLSfNghFxeWv10_thg0-K-erO5-e55dMQeBz0ribQEGcf_HRzqQ/viewform">  here </a> to file a bug report.
            </Center>
        </Center>
    )
    /*
    
    <Button onClick={getTokenAndTryAgain}>
        Click this button to get access to this page, however you should only have to do this on the test account. Please report this to Analytics.
    </Button>
    */
    }
    if(isError && error===403) {
        return (
            <Center w="100%" h="100%" bg="brand.300"> 
                <Center padding="12px" letterSpacing="0.1em" fontFamily="Texta Alt Heavy" color="white">
                    ACCESS DENIED
                </Center>
            </Center>
        )
    }
    return (
        <Flex color="white" height="100%" overflow="hidden">
            <Sidebar isLoading={ui.isLoading} data={genSidebarRows(sidebarRows,query, twowayRows)} status={ui.status} params={queryParams}/>
            <Flex flex="1" bg="white" paddingLeft="24px" paddingRight="24px" paddingTop="12px" paddingBottom="12px" overflowY="auto" flexDirection="column">
                <Flex marginBottom={state==='US' ? "48px" : "8px"}>
                    <Flex flexDirection="column">
                        <Heading color="brand.100" fontSize="44px" fontWeight="500" fontFamily="Texta Alt Bold"> 
                        {etype==='historic' ? 'Demographic' : 'Projected'}: {state!=='US' ? category : 'National Toplines'} 
                        </Heading>
                        {state!== 'US' && (county ? <Heading color="brand.100" fontSize="24px" fontWeight="500" fontFamily="Texta Alt Bold" textTransform="uppercase"> 
                        {county} {mapgeo === 'DMA' ? '' : 'County, '+state}
                        </Heading>  
                        : <Heading color="brand.100" fontSize="24px" fontWeight="500" fontFamily="Texta Alt Bold" textTransform="uppercase"> 
                        {state}
                        </Heading>)}
                    </Flex>
                    <Spacer />
                    {query.state === 'US' && <Center color="black" paddingLeft="12px" paddingRight="12px" marginTop="12px" fontFamily="Texta Alt Bold" letterSpacing=".01em" color="brand.100"> Last Updated: October 3rd, 2021 </Center>}
                    {query.state !== 'US' && <CountySelect counties={ui.data?.countyselect} dmas={ui.data?.dmaselect} cycles={ui.data?.electorateselect} states={ui.data?.stateselect} isLoading={ui.isLoading} lastupdated={ui.data?.lastupdated} />}
                </Flex>
                {cycle.indexOf('Primary') !== -1 && <Alert status='info' color="black" bg="#abebf4" fontFamily="Texta Alt Medium" padding="30px">
                        <AlertIcon />
                        <AlertTitle mr={2}>{primaryDocumentationDict[state].title}</AlertTitle>
                        <AlertDescription>{primaryDocumentationDict[state].description}</AlertDescription>
                    </Alert>
                }
                 <Flex minHeight="300px" flexDirection="row" overflowX="visible" overflowY="visible">
                    <Box
                    display={state !== "US" ? "flex" :'flex'}
                    flexDirection="column"
                    flexShrink="2"
                    overflowY="auto"
                    color="black"
                    overflowX="visible">
                        <Center> Hover over the columns to see their definitions. </Center>
                        {!!state && !!cycle && <Box
                        width={state === 'US' ? "100%" : "min-content"}
                        > 
                            <ExplorerTable tableData={data} columns={genColumns(category,etype,state)} loading={isFetching} state={state} etype={etype} getTooltipText={getTooltipText}/> 
                        </Box>}
                        {state==='US' && <Center> 
                        <Box 
                            w={props.state !== 'US' ? "65%" : '100%'}
                            maxWidth={props.state!=='US' ? "100%" : "60%"}>
                            Sources:
                            <UnorderedList marginLeft="20px">
                                <ListItem>2022 DNC Party Model </ListItem>
                                <ListItem>2022 Clarity Turnout Model </ListItem>
                            </UnorderedList>
                            Notes:
                            <UnorderedList marginLeft="20px">
                                <ListItem>The DNC Party Model measures generic Democratic support as estimated by a voters partisan registration. When we receive candidate specific scores we will incorporate them into our estimates above.</ListItem>
                                <ListItem> The Clarity Turnout Model was last updated in October, 2021 and is available to all hard side entities through our partnership with the DNC.  </ListItem>
                            </UnorderedList> 
                        </Box>
                        </Center>}
                    </Box>
                    {query.state!=='US' && <Graph /> }
                </Flex>
                {query.state!=='US' && <Map data={mapData} ui={mapUI} /> } 
            </Flex>
        </Flex>
    )
}
//position="absolute" top="55px" right="0px" left="0px" bottom="0px"


export default Explorer