import { AddIcon, ChevronDownIcon, MinusIcon } from '@chakra-ui/icons';
import { Alert, AlertDescription, AlertIcon, Box, Button, Center, Flex, IconButton, ListItem, Menu, MenuButton, MenuItem, MenuList, Spacer, Spinner, UnorderedList } from "@chakra-ui/react";
import { useState } from 'react';
import { Bar, BarChart, LabelList, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { StringParam, useQueryParam } from 'use-query-params';
import { genMenuItems, QueryMenu } from '../../base/components/QueryMenu.js';
import { formatPercent, toCurrency } from '../utils.js';
const valuesMap = [
    {'plain': 'Turnout Rate', 'query':'county_turnout', description: 'County level turnout rate.'},
    {'plain': 'Registered Share', 'query':'county_reg_share', description: 'The share of registered voters held by a given group in the county. Selecting "for Total" will return 100% for all counties!'},
    {'plain': 'Vote Share', 'query':'county_elec_share', description: 'The vote share held by a given group in the county. Selecting "for Total" will return 100% for all counties!'},
    {'plain': 'Vote Share - Registration Share Difference', 'query':'elec_reg_diff', description:'The difference between the share of electorate and the share of registration a group holds in the county. Selecting "for Total" will return 0% for all counties!'}
]


const GEOS_DEFAULT = [
    {text: 'County', id:'county', payload: {county: undefined, mapgeo: undefined}},
    {text: 'DMA', id: 'DMA', payload: {county: undefined, mapgeo: 'DMA'}}, 
]

const DEFAULT_CATEGORIES = ['Male', 'Female']

const MapControls = (props) => {
    const [mapsub, setMapSub] = useQueryParam('mapsub', StringParam);
    const [mapval, setMapVal] = useQueryParam('mapval', StringParam);
    const [controlsToggle, setControlsToggle] = useState(false)
    const [category] = useQueryParam('category', StringParam);
    const [cycle] = useQueryParam('cycle', StringParam);
    const categories = category !== 'Media Market' ? props.ui.data?.categoryselect ?? DEFAULT_CATEGORIES : []
    const valuesMapFiltered = category != 'Media Market' ? valuesMap : [{'plain': 'Turnout Rate', 'query':'county_turnout', description: 'County level turnout rate.'}]
    return (
    <Flex
    position="absolute" 
    top="20px" 
    right="20px"
    flexDirection="Column"
    bg="white"
    boxShadow="0px 4px 6px 0px rgb(0,0,0,20%)"
    w="min-content"
    >
        <Center 
        zIndex="100"  
        flexDirection="row" 
        bg="brand.300" 
        max-width="100%"
        >
            <IconButton icon={controlsToggle ? <AddIcon /> : <MinusIcon />} bg="brand.300" onClick={() => setControlsToggle(!controlsToggle)}/>
            <QueryMenu val_key='mapgeo' data={genMenuItems(GEOS_DEFAULT,'mapgeo')} params={{'mapgeo': StringParam}} default_val="County" />
            <Menu  bg="brand.300"  color="white" border="0px">
                <MenuButton as={Button}  bg="brand.300" rightIcon={<ChevronDownIcon />} variant="solid">
                    {mapval ? valuesMap.filter(v=>v.query===mapval)[0]?.plain : 'Turnout Rate'}
                </MenuButton>
                <MenuList bg="brand.300" padding="0px">
                {valuesMapFiltered.map( (c) => {
                    return ( 
                        <MenuItem key={c.query} value={c.query} flexDirection="column" alignItems="left" onClick={()=>{
                            if (c.query!=='county_turnout' && (mapsub === 'all' || !mapsub) ) {
                                setMapVal(c.query)
                                setMapSub(props.ui.data.categoryselect[0])
                            } else {
                                setMapVal(c.query)
                            }
                        }} _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" paddingLeft="14px"> 
                            <Box 
                            color= "white"
                            fontSize= "14px"
                            letterSpacing= "0.1em"
                            fontFamily= "Texta Alt Heavy"
                            > {c.plain} </Box>
                            <Box maxWidth="330px" textTransform="none"> {c.description} </Box>
                        </MenuItem>
                    )
                })}
                </MenuList>
            </Menu>
            <Center paddingLeft="8px" paddingRight="8px" fontStyle="italic"> for </Center> 
            <Box>
            <QueryMenu val_key='mapsub' data={[{'text': 'Total', id: 'acss', payload: {mapsub: undefined}}].concat(genMenuItems(categories,'mapsub'))} params={{'mapsub': StringParam}} default_val="Total"/>
            </Box>
        </Center>
        {!controlsToggle && <Flex color="black" height="min-content" bg="white" zIndex="99" flexDirection="column" padding="12px">
            {cycle.indexOf('Primary')!==-1 && 
                <Alert status='info' color="black" bg="#abebf4" fontFamily="Texta Alt Medium">
                <AlertIcon />
                <AlertDescription> Primary election results not tracked in Election Explorer </AlertDescription>
                </Alert>
            }
            {cycle.indexOf('Primary')===-1 && <><Center fontFamily="Texta Alt Bold" fontSize="18px" color="brand.100">Two-Way 20{cycle === '22' ? '20' : cycle} {props.ui?.data?.stateelecsummary[0]?.race} Election  Results </Center>
            <Center w="100%" h="50%">
                <ResponsiveContainer w="100%" h="100%" minHeight="100px" minWidth="100px"> 
                <BarChart
                data={props.ui?.data?.stateelecsummary}
                height={64}
                stackOffset="expand"
                layout="vertical"
                margin={{ top: 8, right: 16, bottom: 8, left: 16 }}>
                    <YAxis hide dataKey="state" type="category" />
                    <XAxis hide type="number"/>
                    <Bar dataKey="dvr" stackId="a" fill="#0968b1">
                    <LabelList dataKey="dvr" position="insideLeft" formatter={(val)=>formatPercent(val)} fill="white"/>
                    </Bar>
                    <Bar dataKey="rvr" stackId="a" fill="#e8413d">
                    <LabelList dataKey="rvr" position="insideRight" formatter={(val)=>formatPercent(val)} fill="white" />
                    </Bar>
                    <ReferenceLine x={.5} stroke="black" strokeDasharray="3 3" />
                </BarChart>
                </ResponsiveContainer>
            </Center> </>
            }
        <UnorderedList marginLeft="40px">
            <ListItem><b>Turnout: </b>{toCurrency(Math.round(props.ui?.data?.stateturnoutsummary[0]?.voted))}  </ListItem>
            <ListItem><b>Registration:</b> {toCurrency(Math.round(props.ui?.data?.stateturnoutsummary[0]?.reg))}  </ListItem>
        </UnorderedList>
        </Flex>
        }

    </Flex>
    )
}

const Legend = (props) => {
    const [mapval] = useQueryParam('mapval', StringParam);
    if (!props.data) { 
        return(
            <Center position="absolute" 
            bottom="10px" 
            right="80px" 
            zIndex="100"
            color="brand.100"
            bg="white"
            paddingTop="16px"
            paddingBottom="16px"
            paddingLeft="20px"
            paddingRight="20px"
            textTransform="uppercase"
            boxShadow="0px 4px 6px 0px rgb(0,0,0,20%)"
            borderRadius="2px"
            w="390px"
            h="100px"
            fontFamily="Texta Alt Bold"
            >        <Spinner /></Center>
        )
    }
    if (!props.legend){
        return(
            <Center position="absolute" 
            bottom="10px" 
            right="80px" 
            zIndex="100" 
            bg="brand.300"
            color="white"
            paddingTop="16px"
            paddingBottom="16px"
            paddingLeft="20px"
            paddingRight="20px"
            textTransform="uppercase"
            boxShadow="0px 4px 6px 0px rgb(0,0,0,20%)"
            borderRadius="2px"
            h="90px"
            fontSize="24px"
            w="390px"
            fontFamily="Texta Alt Bold"
            >NOT ENOUGH DATA</Center>
        )
    }
    return (
        <Box position="absolute" 
        bottom="10px" 
        right="80px" 
        zIndex="100" 
        bg="white"
        color="black"
        paddingTop="16px"
        paddingBottom="10px"
        paddingLeft="20px"
        paddingRight="20px"
        textTransform="uppercase"
        boxShadow="0px 4px 6px 0px rgb(0,0,0,20%)"
        borderRadius="2px"
        w="390px"
        fontFamily="Texta Alt Heavy"
        >
            <Box color="black" letterSpacing= "0.1em" fontSize="14px" paddingBottom="12px"> 
                {mapval ? valuesMap.filter(v=>v.query===mapval)[0]?.plain : 'Turnout Rate'} 
            </Box>
            <Box bgGradient={"linear(to-r,"+props.scaleColors.minColor+','+props.scaleColors.maxColor+")"} h="12px" borderRadius="20px"></Box>
            <Box display="flex">
                <Box>{formatPercent(props.legend.min.val)} </Box>
                <Spacer /> 
                <Box> {formatPercent(props.legend.max.val)} </Box>
            </Box>
        </Box>
    )
}

export { MapControls, Legend };
