import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, HStack
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import {
    useHistory
} from "react-router-dom";

const link_list = [
    {'page':'/explorer?category=Gender&cycle=20&etype=historic&state=AZ','name':'Electorate Explorer','key':'explorer', 'opacity':0},
    {'page':'/field/universe_explorer?category=Race&state=AZ&support=70_100&turnout=70_100&universe=generic&widevalue=doors&scorecolormap=turnout','name':'Universe Explorer','key':'field', 'opacity':0},
    {'page':'/tv/tv_explorer?category=Station&election=US%20Presidential%202020%20General&state=AZ&enddate=2020-11-03&startdate=2019-01-01','name':'TV Explorer (WIP)','key':'tv', 'opacity':0},
    {'page': 'https://drive.google.com/drive/u/0/folders/1Rm5hDkbWK3sjVURTPtYQAQ3NsboUMZNN', 'name': 'Documentation','key':'docs', 'opacity':0}
]

const DATA_URL = process.env.REACT_APP_BACKEND_API_URL+'/ui/pages'
const audience = process.env.REACT_APP_BACKEND_API_AUDIENCE;
const scope = 'read:explorer';

function usePagesData() {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["tableData"], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: DATA_URL,
            headers: {'Authorization': `Bearer ${accessToken}`}
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) {
            errorMessage = err.response.status;

        }
        if(errorMessage===403){
            throw 403
        } else {
            throw 404
        }
      }, {
        retry: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchInterval: false
    });
}

const LinkButtons = (props) => {
    const { data } = usePagesData();
    const history = useHistory();
    const [links, setLinks] = useState([{'page': 'https://drive.google.com/drive/u/0/folders/1Rm5hDkbWK3sjVURTPtYQAQ3NsboUMZNN', 'name': 'Documentation','key':'docs', 'opacity':0}])

    useEffect(()=>{
        let linkAr = [];
        link_list.forEach(l=>{
            if(data){
                if (data?.pages.filter(d=>l.key===d.page.split('_')[0]).length > 0) {
                    linkAr.push(l)
                }
            }
        })
        linkAr.push({'page': 'https://drive.google.com/drive/u/0/folders/1Rm5hDkbWK3sjVURTPtYQAQ3NsboUMZNN', 'name': 'Documentation','key':'docs', 'opacity':0})
        setLinks(linkAr)
    }, [data])
    const lbs = links.map( (l) => {
        let stateAr = ['A', 'B'] 
        if (stateAr[0].length !== 0){ // Auth logic
            return ( <Box 
                key={l.page}
                onClick={() => {
                    if(l.key === 'docs') {
                        window.open(l.page)
                    } else {
                            history.push(l.page);
                    }
                } }
                borderBottom="4px solid"
                borderBottomColor={"rgba(223, 59, 65,"+l.opacity+")"}
                borderTop="2px solid brand.100"
                as="button"
                fontFamily="Texta Alt Heavy"
                h="100%"
                w="fit-content"
                paddingLeft="16px"
                paddingRight="16px"
                whiteSpace="nowrap"
                color="#fff"
                marginBottom="0px"
                letterSpacing=".1em"
                fontSize="14px"
                lineHeight="55px"
                transitionProperty="border-color, color"
                transitionDuration=".5s"
                >
                    <Box>{l.name}</Box>
                </Box> )
        } else{
            return null
        }
    });

    return (
        <HStack marginBottom="0px" flexGrow="1" marginLeft="32px">
            {lbs}
        </HStack>
    )
}

export default LinkButtons