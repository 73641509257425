import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useQueryParams } from 'use-query-params';
// const defaultData = ['Race', 'Urbanicity', 'Testsatsa', 'Tesats', 'A', 'B']

export const QueryMenu = (props) => {
    const [query, setQuery] = useQueryParams(props.params);
    const data = props.data
    return (
        <Menu  bg="brand.300"  color="white" border="0px">
        <MenuButton as={Button}  bg="brand.300" rightIcon={<ChevronDownIcon />} variant="solid">
        {props.prepend ? props.prepend : ''} {query[props.val_key] ? props.data.filter(d=>d.id===query[props.val_key]).length > 0 ? props.data.filter(d=>d.id===query[props.val_key])[0].text : props.default_val : props.default_val}
        </MenuButton>
        <MenuList bg="brand.300" padding="0px" zIndex={1000} maxHeight="300px" overflowY="auto" color="white">
        {
            data.map( (c) => {
                return ( 
                        <MenuItem key={c.id} value={c.id} _hover={{bg: 'brand.200', color:'white'}} _focus={{bg: 'brand.200', color:'white'}} variant="solid" onClick={()=>{setQuery(c.payload)}}> 
                        {c.text}
                        </MenuItem>
                )
            })
        }
        </MenuList>
    </Menu>
    )
}
export default QueryMenu

export const genMenuItems = (data, key_val, addToPayload) => {
    return data.map(d=>{
        if (typeof d === 'string' || d instanceof String) {
            let payloadObj = {}
            payloadObj[key_val] = d
            if (addToPayload) {
                payloadObj = {...payloadObj, ...addToPayload}
            }
            return {id: d, text: d, payload: payloadObj}
        } else return d
    })
  }
/*
                        <ListItem letterSpacing=".1em" textTransform="uppercase"> Two-Way </ListItem> 
                        <ListItem
                            letterSpacing=".1em" 
                            marginLeft="0px" 
                            marginRight="32px"
                            fontSize="14px"
                            as="button"
                            textTransform="none"
                            display="flex">
                            <Box>
                            {query.category==='comparator' ?  <ListIcon as={ArrowForwardIcon} color="brand.300" /> : <ListIcon as={ArrowForwardIcon} color="brand.400" />} 
                            </Box>
                            <Box textAlign="left" onClick={()=>history.push('/comparator')}> Comparator </Box>   
                        </ListItem>
*/