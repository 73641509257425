import {
  extendTheme
} from "@chakra-ui/react";

const theme = extendTheme({
    fonts: {
      heading: "Texta Alt Heavy",
      body: "ff-tisa-web-pro"
    },
    colors: {
      brand: {
        100: "#0c85c8",
        200: "#0967b0",
        300: '#df3b41',
        400: '#065795'
      }
    },
    styles: {
      global: {
        body: {
          fontFamily: "Texta Alt Regular" 
        },   
        button: {
          textTransform: "uppercase",
          _hover: {
            color: 'brand.300'
          }
        },
        table: {
          fontFamily: "Texta Alt Regular",
          color: "black"
        },
        option: {
          textTransform: "uppercase"
        },     
        select: {
          textTransform: "uppercase",
          bg: "brand.100",
          _hover: {
            bg: 'brand.300'
          }
        },
      }

    },
    components: {
      Button: {
          // 1. We can update the base styles
          baseStyle: {
            fontWeight: "500", // Normally, it is "semibold",
            fontFamily: "Texta Alt Heavy",
            border: "0px solid",
            fontSize: "14px",
            lineHeight: "2.8em",
            outline: "none",
            borderRadius: "0px",
            color: "white",
            padding: "0 5px",
            textTransform: "uppercase",
          },
          // 2. We can add a new button size or extend existing
          sizes: {
            xl: {
              h: "56px",
              fontSize: "lg",
              px: "32px",
            },
          },
          variants: {
            solid: {
              _hover: {
                bg: 'brand.200',
                color: 'white'
              },
              _active: {
                bg: 'brand.200',
                color: 'white'
              },
              bg: "brand.100",
              color: "white",
              fontSize: "14px",
              letterSpacing: "0.1em",
              fontFamily: "Texta Alt Heavy"
            },
            outline: {
              _hover: {
                bg: 'brand.200',
                color: 'white'
              },
              _active: {
                bg: 'brand.200',
                color: 'white'
              },
              bg: "brand.100",
              color: "white",
              fontSize: "14px",
              letterSpacing: "0.1em",
              fontFamily: "Texta Alt Heavy"
            }
          },
        // 3. We can add a new visual variant
        defaultProps: {
          bg: "brand.100"
        }
      },
      Box: {
        variants: {
          header: {

            fontSize: "14px",
            letterSpacing: "0.1em",
            fontFamily: "Texta Alt Heavy"
          },
        },
      },
      MenuItem: {
        // 1. We can update the base styles
        baseStyle: {
          field: {
            fontWeight: "500", // Normally, it is "semibold",
            fontFamily: "Texta Alt Heavy",
            border: "0px solid",
            fontSize: "14px",
            lineHeight: "2.8em",
            outline: "none",
            borderRadius: "0px",
            color: "white",
            padding: "0 5px",
            textTransform: "uppercase",
            _hover: {
              bg: 'brand.200'
            },
          }
        },
        // 2. We can add a new button size or extend existing
        sizes: {
          xl: {
            h: "56px",
            fontSize: "lg",
            px: "32px",
          },
        },
        variants: {
          outline: {
            field: {
              _hover: {
                bg: 'brand.200'
              },
              bg: "brand.300",
              color: "white",
              fontSize: "14px",
              letterSpacing: "0.1em",
              borderRadius: "0px",
              lineHeight: "2.8em"
            }
          },
        },
        // 3. We can add a new visual variant
        defaultProps: {
          _hover: {
            bg: 'brand.200'
          },
          bg: "brand.100"
        }
      },
      Select: {
        // 1. We can update the base styles
        baseStyle: {
          field: {
            fontWeight: "500", // Normally, it is "semibold",
            fontFamily: "Texta Alt Heavy",
            border: "0px solid",
            fontSize: "14px",
            lineHeight: "2.8em",
            outline: "none",
            borderRadius: "0px",
            color: "white",
            padding: "0 5px",
            textTransform: "uppercase",
            _hover: {
              bg: 'brand.200'
            },
          }
        },
        // 2. We can add a new button size or extend existing
        sizes: {
          xl: {
            h: "56px",
            fontSize: "lg",
            px: "32px",
          },
        },
        variants: {
          outline: {
            field: {
              _hover: {
                bg: 'brand.200'
              },
              bg: "brand.300",
              color: "white",
              fontSize: "14px",
              letterSpacing: "0.1em",
              borderRadius: "0px",
              lineHeight: "2.8em"
            }
          },
        },
        // 3. We can add a new visual variant
        defaultProps: {
          _hover: {
            bg: 'brand.200'
          },
          bg: "brand.100"
        }
      },
      Table: {
        // 1. We can update the base styles
        baseStyle: {
        },
        variants: {
          simple: {
            tbody:{
              borderCollapse: 'separate',
              fontWeight: 500
            },
            table: {
              fontFamily:'Texta Alt Regular',
              borderSpacing: 0,
              borderCollapse: 'separate',
              wordWrap: 'normal'
            },
            thead: {
              th:{
                fontFamily:'Texta Alt Regular',
                fontWeight: 500,
                color: 'black',
                borderBottom: '2px solid black'
              }
            },
            tfoot: {
              td:{
                borderBottom: '0px solid black',
                borderTop: '2px solid black'
              }
            },
            td: {
              paddingInlineStart: '8px',
              paddingInlineEnd: '8px',
              borderCollapse: 'separate',
              wordWrap: 'normal',
              borderBottom: '2px solid grey'
              
            },
            th: {
              paddingInlineStart: '8px',
              paddingInlineEnd: '8px',
              borderCollapse: 'separate',
              wordWrap: 'normal',
              verticalAlign: 'bottom'
            }
          },
        },
      },
      Popover: {
        baseStyle: {
          bg: 'red',
          body: {
            bg: 'brand.300',
            border: '0px white solid'
          }
        }
      }
    },
  })

  export default theme


  /*



            td: {
              width: '0.0000000001%',
              whiteSpace: 'nowrap',
              paddingInlineStart: '8px',
              paddingInlineEnd: '8px'
            },
            th: {
              width: '0.0000000001%',
              whiteSpace: 'nowrap',
              paddingInlineStart: '8px',
              paddingInlineEnd: '8px'
            }
  */