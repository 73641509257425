
import { SkeletonText } from "@chakra-ui/react";
import React from 'react';

export const toCurrency = (numberString) => {
    let number = parseFloat(numberString);
    return number.toLocaleString('USD');
}

export function formatPercent(number) {
    return (parseFloat(number*100).toFixed(0)+'%')
}

const columnMap = [
    {
        Header: 'People',
        accessor: 'people',
        isNumeric: true,
        isColor: false,
        Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => {
                  return row?.people + sum
              }, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
      },
    {
      Header: 'Doors',
      accessor: 'doors',
      isNumeric: true,
      isColor: false,
      Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
      Footer: (info,t) => {
          const total = React.useMemo(
            () =>
              info.data.reduce((sum, row) => {
                return row?.doors + sum
            }, 0),
            [info.data]
          )
          return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
    },
      {
        Header: 'Phones',
        accessor: 'phones',
        isNumeric: true,
        isColor: false,
        Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
        borderRight: '1px solid black',
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => {
                  return row?.phones + sum
              }, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )}
      },
      {
        Header: 'People Share',
        accessor: 'people_share',
        isNumeric: true,
        sortType: 'number',
        Cell: props => <div> {formatPercent(props.value)} </div>,
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => row?.doors_share + sum, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
    },
      {
          Header: 'Doors Share',
          accessor: 'doors_share',
          isNumeric: true,
          sortType: 'number',
          Cell: props => <div> {formatPercent(props.value)} </div>,
          Footer: (info,t) => {
              const total = React.useMemo(
                () =>
                  info.data.reduce((sum, row) => row?.doors_share + sum, 0),
                [info.data]
              )
              return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
      },
    {
        Header: 'Phones Share',
        accessor: 'phones_share',
        isNumeric: true,
        sortType: 'number',
        Cell: props => <div> {formatPercent(props.value)} </div>,
        borderRight: '1px solid black',
        Footer: (info,t) => {
            const total = React.useMemo(
              () =>
                info.data.reduce((sum, row) => row?.doors_share + sum, 0),
              [info.data]
            )
            return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
    },
    {
      Header: 'Door Coverage',
      accessor: 'door_coverage',
      sortType: (a,b,col) => { // For whatever reason sortType number can't sort negatives.
        let returnVal = a.values[col] > b.values[col] ? 1 : -1
        return returnVal
      },
      colorAccessor: 'door_coverage',
      isNumeric: true,
      sortDescFirst: true,
      isColor: true,
      Cell: props => <div> {formatPercent(props.value)} </div>,
      Footer: (info,t) => {
        const total = React.useMemo(
          () =>
            info.data.reduce((sum, row) => row?.doors_share + sum, 0),
          [info.data]
        )
        return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
    }, 
    {
      Header: 'Phone Coverage',
      accessor: 'phone_coverage',
      sortType: (a,b,col) => { // For whatever reason sortType number can't sort negatives.
        let returnVal = a.values[col] > b.values[col] ? 1 : -1
        return returnVal
      },
      colorAccessor: 'phone_coverage',
      isNumeric: true,
      sortDescFirst: true,
      isColor: true,
      Cell: props => <div> {formatPercent(props.value)} </div>,
      Footer: (info,t) => {
        const total = React.useMemo(
          () =>
            info.data.reduce((sum, row) => row?.doors_share + sum, 0),
          [info.data]
        )
        return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> - </SkeletonText> )}
    }, 
]

export const genColumns = (category) => {
    let cols = []
    category = !category ? 'Race' : category
    cols.push({'Header': category, 
    'accessor': 'cat_val', 
    'isNumeric':false,
    Footer: 'Total:',
    font: 'Texta Alt Heavy',
    sortType: (ra, rb, colid, desc)=>{
        const manualSortDict = {
            'Modeled College Likelihood': {
            'High Likely': 5,
            'Likely': 4,
            'Uncertain': 3,
            'Unlikely': 2,
            'High Unlikely': 1
            },
            'Modeled Dem Support': {
            'Strong Dem': 5,
            'Lean Dem': 4,
            'Uncertain': 3,
            'Lean GOP': 2,
            'Strong GOP': 1
            }
        }
        ra = ra.values[colid]
        rb = rb.values[colid]
        if (category in manualSortDict){
            ra = manualSortDict[category][ra];
            rb = manualSortDict[category][rb];
        }
        let sortVal = ra>rb ? 1 : -1
        sortVal = desc ? sortVal*-1 : sortVal
        return sortVal;
        }
    })
    cols = cols.concat(columnMap)
    return cols
  }

  export const genWideColumns = (category, data) => {
    let cols = []
    category = !category ? 'Race' : category
    cols.push({'Header': category == 'county_name' ? 'County' : 'Turnout Score',  // TODO ADD THIS AS A PARAMETER
    'accessor': category, 
    'isNumeric':false,
    Footer: 'Total:',
    font: 'Texta Alt Heavy',
    sortType: (ra, rb, colid, desc)=>{
        const manualSortDict = {
            'Modeled College Likelihood': {
            'High Likely': 5,
            'Likely': 4,
            'Uncertain': 3,
            'Unlikely': 2,
            'High Unlikely': 1
            },
            'Modeled Dem Support': {
            'Strong Dem': 5,
            'Lean Dem': 4,
            'Uncertain': 3,
            'Lean GOP': 2,
            'Strong GOP': 1
            }
        }
        ra = ra.values[colid]
        rb = rb.values[colid]
        if (category in manualSortDict){
            ra = manualSortDict[category][ra];
            rb = manualSortDict[category][rb];
        }
        let sortVal = ra>rb ? 1 : -1
        sortVal = desc ? sortVal*-1 : sortVal
        return sortVal;
        }
    })

    if (data && data.length>0){
      console.log(data)
      Object.keys(data[0]).forEach(k=>{
        if (k!==category){
            cols.push({'Header': k.replace('_',' '), 
            'accessor': k, 
            'isNumeric':true,
            Cell: props => <div> {toCurrency(Math.round(props.value))} </div>,
            Footer: (info,t) => {
              const total = React.useMemo(
                () =>
                  info.data.reduce((sum, row) => {
                    return row[k] + sum
                }, 0),
                [info.data]
              )
              return ( <SkeletonText noOfLines={1} isLoaded={!isNaN(total)}> {toCurrency(Math.round(total))} </SkeletonText> )},
            font: 'Texta Alt Regular'
          })
        }
      })
    }

    return cols
  }


export const genMenuItems = (data, key_val, addToPayload) => {
  return data.map(d=>{
      if (typeof d === 'string' || d instanceof String) {
          let payloadObj = {}
          payloadObj[key_val] = d
          if (addToPayload) {
              payloadObj = {...payloadObj, ...addToPayload}
          }
          return {id: d, text: d, payload: payloadObj}
      } else return d
  })
}

export function median(values){
  if(values.length ===0) throw new Error("No inputs");

  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);
  
  if (values.length % 2)
    return values[half];
  
  return (values[half - 1] + values[half]) / 2.0;
}
