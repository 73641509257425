import { useAuth0 } from "@auth0/auth0-react";
import { Button, Center, Flex, Image, Spacer } from "@chakra-ui/react";
import React from 'react';
import LinkButtons from './LinkButtons.js';

var Header = function (props) {
    const {isAuthenticated,logout} = useAuth0();
    const logoutWithRedirect = () =>logout({returnTo: window.location.origin+'/loggedout',});
    return (
        <Flex h="55px" bg="brand.100">
            <Center p="2" padding=".5rem" marginLeft="12px" >
                <Image src="/logo.svg" alt="logo" h="29px" w="119px"/>
            </Center>
            <LinkButtons />
            <Spacer />
            {isAuthenticated && (
            <Center marginRight="12px">
                <Button onClick={() => logoutWithRedirect()} bg="brand.300" minWidth="180px" >
                    Log Out
                </Button>
            </Center>
            )}
        </Flex>
    )
}

export default Header

 /*
  <HStack marginBottom="-2px" flexGrow="1">
                {LinkButtons}
            </HStack>
            <Center padding=".5rem">
            <Menu
            boundary={<Box h="300px" />} closeOnSelect={false}>
                <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<SettingsIcon />}
                    variant="ghost" color="#2580db" size="lg" marginRight="16px"
                />
                <MenuList h="60vh" overflowY="scroll">
                <MenuOptionGroup defaultValue={() => getDefault('cd')} title="CD" type="radio">
                    <MenuItemOption onClick={() => resolveJurisQuery('cd','all')} value="all">All</MenuItemOption>
                    {CDMenuItems}
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup title="LD" type="radio" defaultValue={() => getDefault('ld')}>
                    <MenuItemOption onClick={() => resolveJurisQuery('ld','all')} value="all">All</MenuItemOption>
                    {LDMenuItems}
                </MenuOptionGroup>
                </MenuList>
                </Menu>
            </Center>
 */