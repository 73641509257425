import {
  Box, ListItem, Spinner, Text, UnorderedList
} from "@chakra-ui/react";

export const getTooltipText = (key, etype, header) => {
    if (key === 'cat_val' || etype==='historic') {
      key = key === 'cat_val' ? header : key
        switch (key) {
          case 'Media Market': 
          return (
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Media Market
            </Box>
            <Text>
                  The DNC score which models the ideology of the voter. We convert the score into
                  the following categories:{" "}
                </Text>
                <UnorderedList>
                  <ListItem> Very Liberal: 80+ </ListItem>
                  <ListItem> Somewhat Liberal: 60-80 </ListItem>
                  <ListItem> Moderate: 40-60 </ListItem>
                  <ListItem> Somewhat Conservative: 20-40 </ListItem>
                  <ListItem> Very Conservative: {"<"}20 </ListItem>
                  <ListItem>
                    {" "}
                    Not Scored: a new registrant or someone who does not have enough
                    demographic information to be modeled
                  </ListItem>
                </UnorderedList>
            </Box>
          )
          case 'Ideology': 
          return (
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Ideology
            </Box>
            <Text> The DMA of the voter. This view shows statistics ONLY for the portion of the DMA that is within the state.  </Text></Box>
          )
          case "Race":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Race
              </Box>
              <Text> The ethnicity of the voter. Race is on the voter file in the following key states on the 2022 map, which are analyzed in the Electorate Explorer: FL, GA, NC.  For all other key states, race is modeled. </Text></Box>);
          case "Gender":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Gender
              </Box>
              <Text> The gender of the voter.  Gender is modeled in all states but Wisconsin. </Text></Box>);
          case "Modeled Dem Support":
            return (
              <Box>
                <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                  Modeled Dem Support
                </Box>
                <Text>
                  The DNC score which indicates the likelihood that a voter is a
                  Democrat. This model is built off of a large base of candidate and
                  party ID responses from several cycles. We convert the score into
                  the following categories:{" "}
                </Text>
                <UnorderedList>
                  <ListItem> Strong Dem: 80+ </ListItem>
                  <ListItem> Lean Dem: 60-80 </ListItem>
                  <ListItem> Uncertain: 40-60 </ListItem>
                  <ListItem> Lean GOP: 20-40 </ListItem>
                  <ListItem> Strong GOP: {"<"}20 </ListItem>
                  <ListItem>
                    {" "}
                    Not Scored: a new registrant or someone who does not have enough
                    demographic information to be modeled
                  </ListItem>
                </UnorderedList>
              </Box>
            );
          case "Party":
            return (
              <Box>
                <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                  Party
                </Box>
                <Text>
                  The registered party of the voter. Party registration is reported
                  directly on the voterfile in the following states: AZ, FL, NC, NH,
                  NV, PA. In all other states, we use modeled party affiliation based
                  on the DNC party model. Everyone in these states will appear under
                  NPA / Nonpartisan in the party registration section.. For
                  party-registration states, party is grouped into the following
                  categories:
                </Text>
                <UnorderedList>
                  <ListItem> Democratic </ListItem>
                  <ListItem> Republican </ListItem>
                  <ListItem>
                    {" "}
                    NPA / Nonpartisan - depending on the state this includes
                    Independent, Nonpartisan, Unaffiliated{" "}
                  </ListItem>
                  <ListItem>
                    {" "}
                    Other / Third Party - depending on the state this includes Green,
                    Libertarian, Other{" "}
                  </ListItem>
                </UnorderedList>
              </Box>
            );
          case "Age":
            return (
              <Box>
                <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                  Age
                </Box>
                <Text>
                  The age of the voter. Age is on file in every state except
                  Wisconsin, where it is modeled. We use the following age groupings:
                </Text>
                <UnorderedList>
                  <ListItem> 18-24 years old </ListItem>
                  <ListItem> 25-34 years old </ListItem>
                  <ListItem> 35-49 years old </ListItem>
                  <ListItem> 50-64 years old </ListItem>
                  <ListItem> 65+ years old </ListItem>
                </UnorderedList>
              </Box>
            );
          case "Modeled College Likelihood":
            return (
              <Box>
                <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                  Modeled College Likelihood
                </Box>
                <Text>
                  The DNC score which indicates the likelihood that a voter holds a
                  four-year college degree. We convert the score to categories using
                  the following cutpoints:
                </Text>
                <UnorderedList>
                  <ListItem> High Likely: 75+ </ListItem>
                  <ListItem> Likely: 55-75 </ListItem>
                  <ListItem> Uncertain: 35-55 </ListItem>
                  <ListItem> Unlikely: 15-35 </ListItem>
                  <ListItem> High Unlikely: {"<"}15 </ListItem>
                  <ListItem>
                    {" "}
                    Not Scored: a new registrant or someone who does not have enough
                    demographic information to be modeled{" "}
                  </ListItem>
                </UnorderedList>
              </Box>
            );
          case "Urbanicity":
            return (
              <Box>
                <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                  Urbanicity
                </Box>
                <Text>
                  The density of the area the voter lives in according to the Census.
                  The Census defines urbanicity as the number of people per a Census
                  Designated Place (CDP), this data was provided by the DNC at the
                  individual level based on a voter/registrant’s address. This falls
                  into 3 categories:
                </Text>
                <UnorderedList>
                  <ListItem> Rural - less than 2,500 people per CDP </ListItem>
                  <ListItem> Suburban - 2,500 to 50,000 people per CDP </ListItem>
                  <ListItem> Urban - more than 50,0000 people per CDP </ListItem>
                </UnorderedList>
              </Box>
            );
          case "voted":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Votes Cast
              </Box>
              <Text> The total number of votes cast in the given election. </Text></Box>);
          case "reg":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Registration
              </Box>
              <Text> The number of registered voters according to the most recent state voter file that has been acquired by the DNC. </Text></Box>);
          case "rate":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Turnout Rate
              </Box>
              <Text> The number of votes cast divided by the number of registered voters in a given election. </Text></Box>);
          case "reg_share":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Registration Share
              </Box>
              <Text> The share of registered voters this group occupies (the number of registered voters in a group divided by all registered voters) in a given election.</Text></Box>);
          case "voted_share":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Vote Share
              </Box>
              <Text> The share of people who voted that this group occupies (the number of voters in a group divided by all voters) in a given election. </Text></Box>);
          case "diff":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Vote Share - Registration Difference
              </Box>
              <Text> The difference between the share of voters for this group and the share of registered voters for this group.This metric indicates which groups have outsized influence in vote share relative to their registration share. </Text></Box>);
          default:
            return <Spinner />;
      }
    } else {
      switch(key){
        case "percent_in_state":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Spill
            </Box>
            <Text> The percent of the DMA within the state. For example, if the Yuma AZ-El Centro CA market has a spill of 57% for the state of Arizona, that means only 57% of the registered voters in the DMA are within the state of Arizona.  </Text></Box>);
        case "expander":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Expander
            </Box>
            <Text> Click me to show the counties contained within the media market. </Text></Box>);
        case "voted_share":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Vote Share
            </Box>
            <Text> The share of people who are projected to vote that this group occupies (the number of voters in a group divided by all voters) in a given election. </Text></Box>);
        case "rate":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Turnout Rate
            </Box>
            <Text> The number of projected votes cast divided by the number of registered voters in a given election. </Text></Box>);
        case "dvote":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Est. Democratic Votes
            </Box>
            <Text> The product of a voter’s Modeled Dem Support Score multiplied by their Turnout Score in that cycle. </Text></Box>);
        case "rvote":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Est. Republican Votes
            </Box>
            <Text> The product of the inverse of a voter’s Modeled Dem Support Score multiplied by their Turnout Score in that cycle. </Text></Box>);
        case "rperc":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Est. Republican Vote Share
            </Box>
            <Text> The estimated vote share for the Republican candidate. </Text></Box>);
        case "dperc":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Est. Democratic Vote Share
            </Box>
            <Text> The estimated vote share for the Democratic candidate. </Text></Box>);
        case "marginperc":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Est. Vote Share Margin
            </Box>
            <Text> The difference between the estimated vote share for the Democratic candidate and the Republican candidate. </Text></Box>);
        case "margin":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Est. Vote Share Margin
            </Box>
            <Text> The difference between the estimated votes for the Democratic candidate and the Republican candidate. </Text></Box>);
        case "voted":
          return ( 
            <Box>
            <Box fontFamily="Texta Alt Heavy" fontSize="14px">
              Est. Turnout
            </Box>
            <Text> The sum of all voters’ turnout scores. </Text></Box>);
          default:
            return <Spinner />;
      }
    }
    
}