
//import ExplorerTable from './components/Table.js';
import { useAuth0 } from '@auth0/auth0-react';
import { Center, Flex } from "@chakra-ui/react";
import axios from "axios";
import { useQuery } from "react-query";
import { StringParam, useQueryParams } from 'use-query-params';
import Sidebar from '../base/components/Sidebar.js';


const DATA_URL = process.env.REACT_APP_BACKEND_API_URL+'/data/comparator/table'
const audience = process.env.REACT_APP_BACKEND_API_AUDIENCE;
const scope = 'ga:comparator';

function useTableData(category,cycle,state,county) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["compTableData", category, cycle, county], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: DATA_URL,
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: {category,cycle,state,county}
        }
        const { data } = await axios(requestObj);
        return data;
      }, {
      enabled: (!!category&&!!cycle&&!!state),
    });
}

function useComparatorUI(category,state) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["compUI",state], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: process.env.REACT_APP_BACKEND_API_URL+'/ui/explorer',
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: {part: 'main',state}
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) {
            errorMessage = err.response.status;

        }
        console.log(errorMessage)
        if(errorMessage===403){
            console.log('error?')
            throw new Error('Oh no!')
        }

      }, {
        enabled: (!!category)
      });
}



var Comparator = function (props) { // TODO pull not triggered when switching pages
    const [query] = useQueryParams({
        county: StringParam,
        category: StringParam,
        state: StringParam,
        cycle: StringParam
    });
    const { category, county, state, cycle } = query;
    const { error, isError } = useTableData(category,cycle,state,county);
    const ui = useComparatorUI(category,state);

    if(isError && error!==403){ 
        return (            
        <Center w="100%" h="100%" bg="brand.100"> 
            <Center padding="12px" letterSpacing="0.1em" fontFamily="Texta Alt Heavy" color="white">
                Congratulations, you found a bug! Please report this to analytics.
                Click <a href="https://docs.google.com/forms/d/e/1FAIpQLSfNghFxeWv10_thg0-K-erO5-e55dMQeBz0ribQEGcf_HRzqQ/viewform">  here </a> to file a bug report.
            </Center>
        </Center>
    )
    /*
    <Button onClick={getTokenAndTryAgain}>Click this button to get access to this page, however you should only have to do this on the test account. Please report this to Analytics.</Button>
    */
    }
    if(isError && error===403){
        return (
            <Center w="100%" h="100%" bg="brand.300"> 
                <Center padding="12px" letterSpacing="0.1em" fontFamily="Texta Alt Heavy" color="white">
                    ACCESS DENIED
                </Center>
            </Center>
        )
    }

    return (
    <Flex color="white" height="100%" overflow="hidden">
        <Sidebar isLoading={ui.isLoading} data={ui.data?.sidebar} status={ui.status}/>
        <Flex flex="1" bg="white" paddingLeft="24px" paddingRight="24px" paddingTop="12px" paddingBottom="12px" overflowY="auto" flexDirection="column">
             <Flex minHeight="300px" flexDirection="row" overflowX="visible" overflowY="visible">
                <Center
                display='flex'
                flexDirection="column"
                flexShrink="2"
                overflowY="auto"
                color="black"
                overflowX="visible"
                height='min-content'
                width={state === 'US' ? "100%" : "min-content"}>

                </Center>
            </Flex>
        </Flex>
    </Flex>
    )
                }
//position="absolute" top="55px" right="0px" left="0px" bottom="0px"
/*
                    {
                    !!state && !!cycle && 
                    <ExplorerTable tableData={data} columns={genColumns(category,etype,state)} loading={isFetching} state={state} etype={etype} getTooltipText={getTooltipText}/>
                }
*/

export default Comparator;