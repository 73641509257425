import {
  Box, ListItem, Spinner, Text, UnorderedList
} from "@chakra-ui/react";

export const getTooltipText = (key, etype, header) => {
      key = key === 'cat_val' ? header : key
      console.log(key)
        switch (key) {
          case "Race":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Race
              </Box>
                <Text> The ethnicity of the voter. Race is on the voter file in the following key states on the 2022 map, which are analyzed in the Electorate Explorer: FL, GA, NC.  For all other key states, race is modeled. </Text>
              </Box>);
          case "Age":
            return (
              <Box>
                <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                  Age
                </Box>
                <Text>
                  The age of the voter. Age is on file in every state except
                  Wisconsin, where it is modeled. We use the following age groupings:
                </Text>
                <UnorderedList>
                  <ListItem> 18-24 years old </ListItem>
                  <ListItem> 25-34 years old </ListItem>
                  <ListItem> 35-49 years old </ListItem>
                  <ListItem> 50-64 years old </ListItem>
                  <ListItem> 65+ years old </ListItem>
                </UnorderedList>
              </Box>
            );
          case "doors":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Doors
              </Box>
                <Text> The count of voters with addresses on file. </Text>
              </Box>);
          case "County":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                County
              </Box>
                <Text> The county within the state. </Text>
              </Box>);
          case "Support Score":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Support Score
              </Box>
                <Text> The modeled support score of the voter. Grouped into intervals of 5. Low scores are estimated to be highly Republican, high scores estimated to be highly Democratic. </Text>
              </Box>);
          case "Turnout Score":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Turnout Score
              </Box>
                <Text> The modeled turnout score of the voter. Grouped into intervals of 5. Low scores are estimated to have low probability of turning out; high scores are estimated to have a high probability of turning out. </Text>
              </Box>);          
          case "people":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                People
              </Box>
                <Text> The count of voters. </Text>
              </Box>);
          case "phones":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Phones
              </Box>
                <Text> The count of voters with a phone number on file. </Text>
              </Box>
            );          
          case "people_share":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Phone Share
              </Box>
                <Text> The share of the universe that group comprises. </Text>
              </Box>
            );
          case "doors_share":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Door Share
              </Box>
                <Text> The share of the universe that has addresses. </Text>
              </Box>);
          case "phones_share":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                People Share
              </Box>
                <Text> The share of the universe that has phones. </Text>
              </Box>);
          case "door_coverage":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Door Coverage
              </Box>
                <Text> The share of people who have an address on file for that group. </Text>
              </Box>
            );          
          case "phone_coverage":
            return ( 
              <Box>
              <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                Phone Coverage
              </Box>
                <Text> The share of people who have a phone on file for that group. </Text>
              </Box>
            );
          case "Modeled College Likelihood":
            return (
              <Box>
                <Box fontFamily="Texta Alt Heavy" fontSize="14px">
                  Modeled College Likelihood
                </Box>
                <Text>
                  The DNC score which indicates the likelihood that a voter holds a
                  four-year college degree. We convert the score to categories using
                  the following cutpoints:
                </Text>
                <UnorderedList>
                  <ListItem> High Likely: 75+ </ListItem>
                  <ListItem> Likely: 55-75 </ListItem>
                  <ListItem> Uncertain: 35-55 </ListItem>
                  <ListItem> Unlikely: 15-35 </ListItem>
                  <ListItem> High Unlikely: {"<"}15 </ListItem>
                  <ListItem>
                    {" "}
                    Unscored: a new registrant or someone who does not have enough
                    demographic information to be modeled{" "}
                  </ListItem>
                </UnorderedList>
              </Box>
            );
          default:
            return <Spinner />;
      }
}