
import { Center, Flex, useToast } from "@chakra-ui/react";
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { useQueryParams } from "use-query-params";
// const defaultData = ['Race', 'Urbanicity', 'Testsatsa', 'Tesats', 'A', 'B']

export const DateRangeSelector = (props) => {
    const [query, setQuery] = useQueryParams(props.params); // eslint-disable-line
    const toast = useToast()
    return (
        <Flex color="white" flexDirection="row">
                <SingleDatepicker
                name="date-input"
                date={query.startdate}
                onDateChange={(d) => {
                    if (d.getTime() <= query.enddate.getTime()) {
                        setQuery({startdate: d})
                    } else {
                        toast({
                            title: 'Date Error.',
                            description: "Start date must be earlier than end date.",
                            status: 'error',
                            position: 'top-left',
                            duration: 3000,
                            isClosable: true,
                          })
                    }
                }}
                propsConfigs={{
                    inputProps:{
                        w:'120px',
                        bg: 'brand.300',
                        border: '0px solid black',
                        borderRadius: '0px',
                        fontFamily: 'Texta Alt Heavy',
                        letterSpacing: '0.1em',
                        fontSize: '14px',
                        lineHeight: '2.8em'
                    },
                    dateNavBtnProps: {
                            variant: "outline",
                            bg:'brand.300',
                            color:'white'
                        },
                        dayOfMonthBtnProps: {
                            color:'white',
                             _hover: {
                                 color: 'brand.100'
                            }
                        },
                        calendarPanel: {
                            bg: 'red'
                        }
                }}
            />
            <Center paddingLeft="8px" paddingRight="8px" fontStyle="italic"> to </Center>
            <SingleDatepicker
                name="date-input"
                date={query.enddate}
                onDateChange={(d) => {
                    if (d.getTime() >= query.startdate.getTime()) {
                        setQuery({enddate: d})
                    } else {
                        toast({
                            title: 'Date Error.',
                            description: "End date must be later than start date.",
                            status: 'error',
                            position: 'top-left',
                            duration: 3000,
                            isClosable: true,
                          })
                    }
                }}
                propsConfigs={{
                    inputProps:{
                        w:'120px',
                        bg: 'brand.300',
                        border: '0px solid black',
                        borderRadius: '0px',
                        fontFamily: 'Texta Alt Heavy',
                        letterSpacing: '0.1em',
                        fontSize: '14px',
                        lineHeight: '2.8em'
                    },
                    dateNavBtnProps: {
                        variant: "outline",
                        bg:'brand.300',
                        color:'white'
                        },
                        dayOfMonthBtnProps: {
                        color:'white',
                        _hover: {
                            color: 'brand.100'
                       }
                    },
                    calendarPanel: {
                        bg: 'red'
                    }
                }}
            />
        </Flex>
    )
}
export default DateRangeSelector
