import { useAuth0 } from '@auth0/auth0-react';
import { Box, Center, Flex, Heading, Link, Spacer, Table, TableCaption, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from 'react';
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import {
    encodeQueryParams
} from 'serialize-query-params';
import { DelimitedNumericArrayParam, StringParam, useQueryParams } from 'use-query-params';
import QueryMenu from '../base/components/QueryMenu.js';
import Sidebar from '../base/components/Sidebar.js';
import HaydenTable from "../base/components/Table.js";
import { scoreDistColorDefs, scoreDistColorMaps } from './colormap.js';
import UniverseControlPanel from "./components/UniverseControlPanel.js";
import { getTooltipText } from "./tooltips.js";
import { genColumns, genMenuItems, genWideColumns } from './utils.js';
const sidebarRows = {'CATEGORY': [
        {'id':'Support Score', 'text':'SUPPORT SCORE TOPLINES', 'cat':'category', queryPayload: {category: 'Support Score'}},
        {'id':'Turnout Score', 'text':'TURNOUT SCORE TOPLINES', 'cat':'category', queryPayload: {category: 'Turnout Score'}},
        {'id':'Race', 'text':'Race', 'cat':'category', queryPayload: {category: 'Race'}},
        {'id':'Age', 'text':'Age', 'cat':'category', queryPayload: {category: 'Age'}},
        {'id':'Modeled College Likelihood', 'text':'College Likelihood', 'cat':'category', queryPayload: {category: 'Modeled College Likelihood'}},
        {'id':'County', 'text':'County', 'cat':'category', queryPayload: {category: 'County'}}
    ]}

const UNIVERSES_DEFAULT = [
    {text: 'Turnout', id: 'generic', payload: {universe: 'generic', support: [60, 100], turnout: [30,70]}}, 
    {text: 'Nonpartisan Mail', id:'npam', payload: {universe: 'npam', support: [60,100], turnout: [5,80]}},
    {text: 'VAP', id:'vap', payload: {universe: 'vap'}},
    {text: 'Movers', id:'movers', payload: {universe: 'movers', support: [60,100]}},
    {text: 'New Registrants', id:'newreg', payload: {universe: 'newreg'}},
    {text: 'First Time Voters', id:'ftv', payload: {universe: 'ftv', support: [60,100], turnout: [5,100]}}
]

const queryParams = {
    universe: StringParam,
    turnout: DelimitedNumericArrayParam,
    support: DelimitedNumericArrayParam,
    state: StringParam,
    county: StringParam,
    category: StringParam,
    widevalue: StringParam,
    scorecolormap: StringParam
}

const DATA_URL = process.env.REACT_APP_BACKEND_API_URL+'/field/universe_explorer/table'
const audience = process.env.REACT_APP_BACKEND_API_AUDIENCE;
const scope = 'read:explorer';

function useTableData(category,universe,state,county,turnout,support) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["tableData", category,universe,state,county,turnout,support], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: DATA_URL,
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: encodeQueryParams(
                queryParams,
                {category,state,county,universe, turnout, support}
            )
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) {
            errorMessage = err.response.status;
        }
        if(errorMessage===403){ throw 403 } else { throw 404 }
      }, {
        retry: 0,
      enabled: (!!category&&!!universe&&!!state&&!!turnout&&!!support),
    });
}


function useUniExplorerUI(state) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["uniExpUI",state], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: process.env.REACT_APP_BACKEND_API_URL+'/field/universe_explorer/ui',
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: {part: 'main',state}
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) { errorMessage = err.response.status; }
        console.log(errorMessage)
        if(errorMessage===403){
            console.log('error?')
            throw new Error('Oh no!')
        }
      }, {
        enabled: (!!state)
      });
}

function useUniCountyTable(category,universe,state,county,turnout,support, widevalue) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["uniExpCountyTable",category,universe,state,county,turnout,support, widevalue], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: process.env.REACT_APP_BACKEND_API_URL+'/field/universe_explorer/county_table',
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: encodeQueryParams(
                queryParams,
                {category,state,county,universe, turnout, support,widevalue}
            )
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) { errorMessage = err.response.status; }
        console.log(errorMessage)
        if(errorMessage===403){
            console.log('error?')
            throw new Error('Oh no!')
        }
      }, {
        enabled: (!!category&&!!universe&&!!state&&!!turnout&&!!support&&!!widevalue)
      });
}

function useUniJointTable(category,universe,state,county,turnout,support, widevalue) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["uniExpJointTable",category,universe,state,county,turnout,support, widevalue], async () => {
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const requestObj = {
            method:'get',
            url: process.env.REACT_APP_BACKEND_API_URL+'/field/universe_explorer/joint_table',
            headers: {'Authorization': `Bearer ${accessToken}`},
            params: encodeQueryParams(
                queryParams,
                {category,state,county,universe, turnout, support,widevalue}
            )
        }
        let errorMessage;
        try {
            const { data } = await axios(requestObj);
            return data;
        } catch (err) { errorMessage = err.response.status; }
        console.log(errorMessage)
        if(errorMessage===403){
            console.log('error?')
            throw new Error('Oh no!')
        }
      }, {
        enabled: (!!category&&!!universe&&!!state&&!!turnout&&!!support&&!!widevalue)
      });
}


var UniverseExplorer = function (props) {
    let location = useLocation();
    const [query, setQuery] = useQueryParams(queryParams);
    const { category, state, universe, turnout, support, county, widevalue } = query;
    const { data, isFetching } = useTableData(category,universe,state, county, turnout, support);
    const { data: uidata } = useUniExplorerUI(state);
    const { data: countydata, isFetching: isCountyFetching } = useUniCountyTable(category,universe,state,county,turnout,support, widevalue);
    const { data: jointdata, isFetching: isJointFetching } = useUniJointTable(category,universe,state,county,turnout,support, widevalue);

    useEffect(()=>{
        if (location.pathname==='/field/universe_explorer'){
            if (!category | !turnout | !support | !universe | !state){
                setQuery({category: 'Race', universe: 'generic', turnout: [70,100], support: [70,100], state: 'AZ', widevalue: 'doors'})
            }
        }
    }, [location, category, universe, state, turnout, support, setQuery])

    return (
        <Flex color="white" height="100%" overflow="hidden">
            <Sidebar isLoading={false} data={sidebarRows} status={false} params={queryParams}/>
            <Flex  flexDirection="column" w="100%" overflowY="scroll">
                <Flex paddingLeft="16px" flexDirection="column">
                    <Heading color="brand.100" fontSize="44px" fontWeight="500" fontFamily="Texta Alt Bold">
                       {UNIVERSES_DEFAULT.filter(u=>u.id==query.universe)[0].text} Universe by {query.category}
                    </Heading>
                    {!!county && <Heading color="brand.300" fontSize="24px" fontWeight="500" fontFamily="Texta Alt Bold" textTransform="uppercase"> 
                        {county} County, {state} | {query.turnout[0]} to {query.turnout[1]} Turnout Score Selected | {query.support[0]} to {query.support[1]} Support Score Selected </Heading>}
                    {!county && <Heading color="brand.300" fontSize="24px" fontWeight="500" fontFamily="Texta Alt Bold" textTransform="uppercase"> 
                        {state} | {query.turnout[0]} to {query.turnout[1]} Turnout Score Selected | {query.support[0]} to {query.support[1]} Support Score Selected
                    </Heading> }
                </Flex>
                <Flex flexDirection="row" h="400px">
                    <UniverseControlPanel params={queryParams} counties={uidata?.countyselect}/>
                    <Box
                    paddingTop="20px"
                    flexDirection="column"
                    overflowY="auto"
                    color="black"
                    overflowX="hidden"
                    >
                    <Center> Hover over the columns to see their definitions.   Numbers <br /> are estimated and will fall within 1% of true counts. </Center>
                    <HaydenTable tableData={data} columns={genColumns(category)} loading={isFetching | !category | !turnout | !support | !universe | !state} state={state} etype={'modeled'} getTooltipText={getTooltipText} columnSize={4} />
                    </Box>
                </Flex>
                <Flex
                h="5000px"
                 color="black"
                 paddingBottom="16px"
                flexDirection="column"
                >
 
                    <Flex marginBottom={"20px"} paddingLeft="16px" paddingRight="16px">
                        <Flex flexDirection="column">
                            <Heading color="brand.100" fontSize="44px" fontWeight="500" fontFamily="Texta Alt Bold">
                                {query.category} Details by County
                            </Heading>
                            {!!county && <Heading color="brand.300" fontSize="24px" fontWeight="500" fontFamily="Texta Alt Bold" textTransform="uppercase"> 
                        {county} County, {state} | {query.turnout[0]} to {query.turnout[1]} Turnout Score Selected | {query.support[0]} to {query.support[1]} Support Score Selected </Heading>}
                            { !county && <Heading color="brand.300" fontSize="24px" fontWeight="500" fontFamily="Texta Alt Bold" textTransform="uppercase"> 
                                {state} | {query.turnout[0]} to {query.turnout[1]} Turnout Score Selected | {query.support[0]} to {query.support[1]} Support Score Selected
                            </Heading> }
                        </Flex>
                        <Spacer />
                        <Box paddingTop="32px">
                        <QueryMenu val_key='widevalue' data={genMenuItems(['doors','phones','persons'], 'widevalue')} params={queryParams} prepend="Count Type:"/>
                        </Box>
                    </Flex>
                    <Box height="400px" overflowY="auto" margin="auto">
                        <Center> If you're looking to see counties by turnout or support score, <br /> select "Turnout/Support Score Detail" on the left hand menu. </Center>
                        <HaydenTable tableData={countydata} columns={genWideColumns('county_name', countydata)} loading={isCountyFetching | !category | !turnout | !support | !universe | !state} state={state} etype={'modeled'} getTooltipText={getTooltipText} columnSize={4} />
                    </Box>
                    
                    <Flex marginBottom={"20px"} paddingLeft="16px" paddingRight="16px">
                    <Flex flexDirection="column">
                                <Heading color="brand.100" fontSize="44px" fontWeight="500" fontFamily="Texta Alt Bold">
                                    Turnout x Support Score Distribution
                                </Heading>
                                {!!county && <Heading color="brand.300" fontSize="24px" fontWeight="500" fontFamily="Texta Alt Bold" textTransform="uppercase"> 
                            {county} County, {state} | {query.turnout[0]} to {query.turnout[1]} Turnout Score Selected | {query.support[0]} to {query.support[1]} Support Score Selected </Heading>}
                                {!county && <Heading color="brand.300" fontSize="24px" fontWeight="500" fontFamily="Texta Alt Bold" textTransform="uppercase"> 
                                    {state} | {query.turnout[0]} to {query.turnout[1]} Turnout Score Selected | {query.support[0]} to {query.support[1]} Support Score Selected
                                </Heading> }
                    </Flex>
                    <Spacer />
                        <Box paddingTop="32px">
                            <QueryMenu val_key='scorecolormap' data={genMenuItems(['turnout','support','persuasion'], 'scorecolormap')} params={queryParams} prepend="Universe Purpose:"/>
                        </Box>
                    </Flex>
                    <Center>
                    <Center flexDirection="column">
                    <Box paddingBottom="24px">
                        <Center fontFamily="Texta Alt Heavy" color="brand.100"> Voter Groups </Center>
                        <Center fontSize="12px" fontWeight="500" lineHeight="16px" letterSpacing="0.6px" marginBottom="-10px"> SUPPORT SCORE </Center>
                        <HaydenTable tableData={jointdata} columns={genWideColumns('trn', jointdata)} loading={isJointFetching | !category | !turnout | !support | !universe | !state} state={state} etype={'modeled'} getTooltipText={getTooltipText} columnSize={4} colorMap={scoreDistColorMaps[query.scorecolormap]} colorKey="trn" disableSort={true}/>
                    </Box>
                    <Box>
                        <Center fontFamily="Texta Alt Heavy" color="brand.100"> Voter Density </Center>
                        <Center fontSize="12px" fontWeight="500" lineHeight="16px" letterSpacing="0.6px" marginBottom="-10px"> SUPPORT SCORE </Center>
                        <HaydenTable tableData={jointdata} columns={genWideColumns('trn', jointdata)} loading={isJointFetching | !category | !turnout | !support | !universe | !state} state={state} etype={'modeled'} getTooltipText={getTooltipText} columnSize={4} colorHeatMap={true} colorKey="trn" />
                    </Box>
                    </Center>
                        <Center>
                            <Table w="270px" h="10px" paddingLeft="32px" fontSize="14px" size="sm" paddingTop="24px">
                                <TableCaption fontFamily="Texta Alt Regular" paddingLeft="50px" fontSize="14px" paddingTop="-5px">More expansive documentation of these two tables can be found <Link href="https://docs.google.com/spreadsheets/d/1JKywu3x_Sz4-zjFG76z7kts3ltRcwLRYDfhOuwnDtNE/edit#gid=0" color="brand.300">here.</Link></TableCaption>
                                <Thead>
                                <Tr>
                                <Th>
                                Color
                                </Th>
                                <Th>
                                Definition
                                </Th>
                                </Tr>
                                </Thead>
                                    <Tbody>
                                    {
                                        Object.keys(scoreDistColorDefs[query.scorecolormap]).map(d=>{
                                            return (<Tr> 
                                                <Td bg={d} ></Td>
                                                <Td> {scoreDistColorDefs[query.scorecolormap][d]} </Td>
                                            </Tr>)
                                        })
                                    }
                                    </Tbody>
                            </Table>
                        </Center>
                    </Center>
                </Flex>
            </Flex>
        </Flex>
    )
}



export default UniverseExplorer

/*
 <ExplorerTable tableData={data} columns={genColumns(category,etype,state)} loading={isFetching} state={state} etype={etype} getTooltipText={getTooltipText}/> 
*/