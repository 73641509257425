/// app.js
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Flex, IconButton, Stack, Tooltip
} from "@chakra-ui/react";
import { FlyToInterpolator } from "@deck.gl/core";
import { MVTLayer } from "@deck.gl/geo-layers";
import { MapboxLayer } from "@deck.gl/mapbox";
import DeckGL from "@deck.gl/react";
import { scaleLinear } from "d3-scale";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState
} from "react";
import { StaticMap } from "react-map-gl";
import {
  StringParam,
  useQueryParams
} from "use-query-params";
import { formatPercent, toCurrency } from "../utils.js";
import { Legend, MapControls } from "./MapControls.js";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// Set your mapbox access token here
const MAPBOX_TOKEN =
  "pk.eyJ1IjoicHVsY2lwaGVyYXRkc2NjIiwiYSI6ImNrdW15a2tydDBmZHMyb2xpNHJ5OW9ma3gifQ.vixRoUUeTfFPoFj9GSRPKg";


const stateInitViewState = {
  AZ: {
    longitude: -111.0937,
    latitude: 34.0489,
    zoom: 6,
    pitch: 0,
    bearing: 0,
  },
  GA: {
    longitude: -82.9001,
    latitude: 32.8656,
    zoom: 6.5,
    pitch: 0,
    bearing: 0,
  },
  NC: {
    longitude: -80.0193,
    latitude: 35.7596,
    zoom: 6.7,
    pitch: 0,
    bearing: 0,
  },
  PA: {
    longitude: -77.1945,
    latitude: 40.9033,
    zoom: 7,
    pitch: 0,
    bearing: 0,
  },
  WI: {
    longitude: -89.4879,
    latitude: 44.7844,
    zoom: 6.5,
    pitch: 0,
    bearing: 0,
  },
  NV: {
    longitude: -116.4194,
    latitude: 38.8026,
    zoom: 6,
    pitch: 0,
    bearing: 0,
  },
  NH: {
    longitude: -71.5724,
    latitude: 44.0139,
    zoom: 7.4,
    pitch: 0,
    bearing: 0,
  },
  OH: {
    longitude: -82.9071,
    latitude: 40.4173,
    zoom: 7,
    pitch: 0,
    bearing: 0,
  },
  FL: {
    longitude: -81.9158,
    latitude: 27.6648,
    zoom: 6.2,
    pitch: 0,
    bearing: 0,
  },
  CO: {
    longitude: -105.7821,
    latitude: 39.1501,
    zoom: 6.2,
    pitch: 0,
    bearing: 0,
  },
  MD: {
    longitude: -76.6413,
    latitude: 39.0458,
    zoom: 6.7,
    pitch: 0,
    bearing: 0,
  }
};

const valuesMap = [
  {
    plain: "County Turnout Rate",
    query: "county_turnout",
    description: "County level turnout rate.",
  },
  {
    plain: "County Registered Share",
    query: "county_reg_share",
    description:
      "The share of registered voters held by a given group in the county.",
  },
  {
    plain: "Vote Share",
    query: "county_elec_share",
    description: "The vote share held by a given group in the county.",
  },
  {
    plain: "Vote Share - Registration Share Differential",
    query: "elec_reg_diff",
    description:
      "The difference between the share of electorate and the share of registration a group holds in the county.",
  },
];

const calculateScale = (data) => {
  if (data && data.maprows.length>0) {
    console.log('calc scale')
    let min = data.maprows.reduce(function (prev, curr) {
      return prev.val < curr.val ? prev : curr;
    });
    let max = data.maprows.reduce(function (prev, curr) {
      return prev.val > curr.val ? prev : curr;
    });

    var color = scaleLinear()
      .domain([min.val, max.val])
      .range(["#fff2d1", "#005868"]);
    console.log({ min: min, max: max, color: color });
    return { min: min, max: max, color: color };
  } else {
    return null;
  }
};
const calcColor = (f, leg, data, geo) => {
  var color;
  if (data) {
    let row;
    if (geo === 'DMA'){
      row = data.maprows.filter(
        (d) => d.media_market === f.properties['NAME']
      );
    } else {
      row = data.maprows.filter(
        (d) => d.state + "_" + d.name === f.properties.state_id
      );
    }
    color =
      row.length > 0
        ? leg
            .color(row[0].val)
            .replace(/[^\d,]/g, "")
            .split(",")
        : [10, 170, 180, 0];
    color = color.map((c) => parseInt(c));
    if (row.length > 0) {
      color.push(255); // Push the transparency value
    }
  } else {
    color = [0, 0, 0, 0];
  }

  return color;
};

const calcBorder = (f, leg, data, county, geo) => {
  var color;
  if (data) {
    let row;
    let rowKey = geo == 'DMA' ? 'media_market' : 'name'
    if (geo === 'DMA'){
      row = data.maprows.filter(
        (d) => d.media_market === f.properties['NAME']
      );
    } else {
      row = data.maprows.filter(
        (d) => d.state + "_" + d.name === f.properties.state_id
      );
    }
    color =
      row.length > 0
        ? county === row[0][rowKey]
          ? [0, 0, 0, 255]
          : [0, 0, 0, 70]
        : [10, 170, 180, 0];
    color = color.map((c) => parseInt(c));
  } else {
    color = [0, 0, 0, 0];
  }
  return color;
};


function Map(props) {
  const [query, setQuery] = useQueryParams({
    county: StringParam,
    category: StringParam,
    state: StringParam,
    cycle: StringParam,
    mapsub: StringParam,
    mapval: StringParam,
    etype: StringParam,
    mapgeo: StringParam
  });
  const {state, cycle, mapsub, mapval} = query;

  const legend = useMemo(() => (props.data ? calculateScale(props.data) : null), [props.data]);
  const [clickable, setClickable] = useState(false);
  const [zoom] = useState(6.5)
  const [initialViewState, setInitialViewState] = useState({
    longitude: -82.9001,
    latitude: 32.8656,
    'zoom': zoom,
    pitch: 0,
    bearing: 0,
  });

  const tilesetId = query.mapgeo === 'DMA' ? 'pulcipheratdscc.akwwyk0r' : 'pulcipheratdscc.cywp1915'

  // Mapbox + Deck Stuff
  // DeckGL and mapbox will both draw into this WebGL context
  const [glContext, setGLContext] = useState();
  const deckRef = useRef(null);
  const mapRef = useRef(null);
  const [scaleColors] = useState({
    minColor: "#fff2d1",
    maxColor: "#005868",
  });

  const onMapLoad = useCallback(() => {
    const map = mapRef.current.getMap();
    const deck = deckRef.current.deck;
    map.addLayer(
      new MapboxLayer({ id: "counties", deck }),
      "waterway-river-canal"
    );
  }, []);

  const clickHandler = (t, data, geo) => {
    let row;
    if (geo === 'DMA'){
      row = data.maprows.filter(
        (d) => d.media_market === t?.object?.properties['NAME']
      );
    } else {
      row = data.maprows.filter(
        (d) => d.state + "_" + d.name === t?.object?.properties?.state_id
      );
    }
    if (row.length > 0) {
      setQuery({ county: geo === 'DMA' ? t.object.properties['NAME'] : t.object.properties['NAME'] });
    } else {
      setQuery({ county: undefined });
    }
  };

  const getCursor = () => {
    return clickable ? "pointer" : "grab";
  };

  const ZoomControls = () => {
    return (
      <Stack
      position="absolute" 
      bottom="15px" 
      right="20px"
      flexDirection="Column"
      bg="white"
      zIndex={100}
      spacing={0}
      boxShadow="0px 4px 6px 0px rgb(0,0,0,20%)">
        <Tooltip label='Zoom in'>
        <IconButton icon={<AddIcon />} marginTop="0px" onClick={()=>{
              setInitialViewState({
                ...initialViewState,
                zoom: initialViewState.zoom+0.5,
                transitionDuration: 200,
                transitionInterpolator: new FlyToInterpolator(),
              });
        }}/> 
        </Tooltip>
        <Tooltip label='Zoom out'>
        <IconButton icon={<MinusIcon marginTop="0px" />} onClick={()=>{
              setInitialViewState({
                ...initialViewState,
                zoom: initialViewState.zoom-0.5,
                transitionDuration: 200,
                transitionInterpolator: new FlyToInterpolator(),
              });
        }}/> 
        </Tooltip>
      </Stack>
    )
  }

  // Transition when user changes state
  useEffect(() => {
    // eslint-disable-next-line
    if (!!state) {
      setInitialViewState({
        ...stateInitViewState[state],
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      });
    }

  }, [state]);

  const layers = [
    new MVTLayer({
      data: `https://a.tiles.mapbox.com/v4/${tilesetId}/{z}/{x}/{y}.vector.pbf?access_token=${MAPBOX_TOKEN}`,
      getLineColor: (t) => calcBorder(t, legend, props.data, query.county, query.mapgeo),
      getFillColor: (t) => calcColor(t, legend, props.data, query.mapgeo),
      pickable: true,
      getLineWidth: (f, t) => {
        return query.state + "_" + query.county === f.properties.state_id
          ? 5
          : 0;
      },
      lineWidthUnits: "pixels",
      lineWidthMinPixels: query.mapgeo === 'DMA' ? 2 : 1,
      lineWidthMaxPixels:  5,
      onClick: (t) => clickHandler(t, props.data, query.mapgeo),
      onHover: (t) => {
        let row ;
        if (query.mapgeo === 'DMA'){
          row = props.data?.maprows.filter(
            (d) => d.media_market === t?.object?.properties['NAME']
          );
        } else {
          row = props.data?.maprows.filter(
            (d) => d.state + "_" + d.name === t?.object?.properties?.state_id
          );
        }
        let isClickable = row && (row.length>0)
        setClickable(isClickable);
      },
      updateTriggers: {
        getFillColor: [legend, props.data],
        getLineColor: [legend, props.data, query.county],
        getLineWidth: [query.state, query.county],
      },
      id: "counties",
    }),
  ];
  return (
    <Flex
      marginTop="20px"
      position="relative"
      flex="1"
      minHeight="715px"
      overflow="hidden"
    >
      {<Legend legend={legend} scaleColors={scaleColors} data={props.data} />}
      {<MapControls ui={props.ui} />}
      {<ZoomControls />}
      {
        <DeckGL
          getTooltip={({ object }) => {
            /*
            let row = undefined;
            if (object && object.properties.STUSAB === state) {
              row = props.data?.maprows.filter(
                (d) => d.state + "_" + d.name === object.properties.state_id
              )[0]
            } */ 
            let row = undefined;
            if (query.mapgeo === 'DMA'){
              row = props.data?.maprows.filter(
                (d) => d.media_market === object?.properties['NAME']
              )[0];
            } else {
              row = props.data?.maprows.filter(
                (d) => d.state + "_" + d.name === object?.properties?.state_id
              )[0];
            }
            let rowKey = query.mapmapgeo === 'DMA' ? 'NAME' : 'NAME'
            if(query.cycle.indexOf('Primary') === -1) {
              return (
                object &&
  
                !!row && {
                  html: `
              <b style="font-size: 20px; font-family: Texta Alt Heavy;"> 
                ${formatPercent(row.val)} 
                ${mapval ? valuesMap.filter((v) => v.query === mapval)[0]?.plain : "Turnout Rate"} 
              </b> <br /> 
              <p> For <b> ${mapsub ? mapsub : 'All Groups'} </b> in <b> ${object.properties[rowKey]} ${query.mapgeo === 'DMA' ? '' : ' County'} </b>  </p>
              <b> Key Facts: </b>
              <ul style="color: white; padding-left: 20px;">
              <li> <b> Registered Voters in this Group: </b> ${toCurrency(row.total_reg)} </li>
              <li> <b> 20${cycle} Voters in this Group: </b> ${toCurrency(Math.round(row.total_votes))} </li>
              <li> <b> Democratic Votes Received: </b> ${toCurrency(row.dvr)} (${Math.round((row.dvr/(row.dvr+row.rvr))*100)}%) </li>
              <li> <b> Republican Votes Received: </b> ${toCurrency(row.rvr)} (${Math.round((row.rvr/(row.dvr+row.rvr))*100)}%)  </li>
              <li> <b> Vote Margin: </b> ${toCurrency(row.margin)} (${Math.round(((row.dvr/(row.dvr+row.rvr))*100))-
                Math.round((row.rvr/(row.dvr+row.rvr))*100)}%) </li>
              </ul>
              `,
                  style: {
                    backgroundColor: "#0c85c8",
                    color: "white",
                    fontFamily: "Texta Alt Regular",
                    padding: "12px",
                    boxShadow: "0px 4px 6px 0px rgb(0,0,0,20%)",
                  },
                }
              );
            } else {
              return (
                object &&
  
                !!row && {
                  html: `
              <b style="font-size: 20px; font-family: Texta Alt Heavy;"> 
                ${formatPercent(row.val)} 
                ${mapval ? valuesMap.filter((v) => v.query === mapval)[0]?.plain : "Turnout Rate"} 
              </b> <br /> 
              <p> For <b> ${mapsub ? mapsub : 'All Groups'} </b> in <b> ${object.properties[rowKey]} ${query.mapgeo === 'DMA' ? '' : ' County'} </b>  </p>
              <b> Key Facts: </b>
              <ul style="color: white; padding-left: 20px;">
              <li> <b> Registered Voters in this Group: </b> ${toCurrency(row.total_reg)} </li>
              <li> <b> 20${cycle} Voters in this Group: </b> ${toCurrency(Math.round(row.total_votes))} </li>
              </ul>
              `,
                  style: {
                    backgroundColor: "#0c85c8",
                    color: "white",
                    fontFamily: "Texta Alt Regular",
                    padding: "12px",
                    boxShadow: "0px 4px 6px 0px rgb(0,0,0,20%)",
                  },
                }
              );
            }
            
          }}
          ref={deckRef}
          initialViewState={initialViewState}
          getCursor={getCursor}
          controller={{scrollZoom: false}}
          onViewStateChange={(vs)=>setInitialViewState(vs.viewState)}
          onWebGLInitialized={setGLContext}
          layers={layers}
          glOptions={{
            /* To render vector tile polygons correctly */
            stencil: true,
          }}
        >
          {glContext && (
            /* This is important: Mapbox must be instantiated after the WebGLContext is available */
            <StaticMap
              ref={mapRef}
              gl={glContext}
              mapStyle="mapbox://styles/developmentdha/ckjro7j822jpo19m850oh6f19"
              mapboxApiAccessToken={MAPBOX_TOKEN}
              onLoad={onMapLoad} />
            
          )}
        </DeckGL>
      }
    </Flex>
  );
}

export default Map;

/*




(, "settlement", (, ("US"), true, false), "disputed_settlement", ( === "true", (, ("US"), true, false)), false)
(, "settlement", (, ("US"), true, false), "disputed_settlement", ( === "true", (, ("US"), true, false)), false)

https://api.mapbox.com/styles/v1/pulcipheratdscc/ckvb70idn231d15nvp6nwd19q/wmts?access_token=pk.eyJ1IjoicHVsY2lwaGVyYXRkc2NjIiwiYSI6ImNrdW15a2tydDBmZHMyb2xpNHJ5OW9ma3gifQ.vixRoUUeTfFPoFj9GSRPKg

https://api.mapbox.com/styles/v1/pulcipheratdscc/ckvb70idn231d15nvp6nwd19q.html?title=view&access_token=pk.eyJ1IjoicHVsY2lwaGVyYXRkc2NjIiwiYSI6ImNrdW15a2tydDBmZHMyb2xpNHJ5OW9ma3gifQ.vixRoUUeTfFPoFj9GSRPKg&zoomwheel=true&fresh=true#4.84/35.29/-101.6

      {data && <MapControls subgroups={data.controlrows} /> }

In this case, you need to explicitly inform deck.gl to re-evaluate getFillColor for all data items. You do so by defining updateTriggers:

  const layer = new ScatterplotLayer({
    ... // Other props
    getFillColor: d => d.male ? maleColor : femaleColor,
    updateTriggers: {
        getFillColor: [maleColor, femaleColor]
    }
  });



        <MapGL
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle="mapbox://styles/pulcipheratdscc/ckumymi07035x18kqsuv81pk1"
        onClick={clickHandler}
        {...viewport}
        height="200px"
        width="200px"
        position="relative"
        onViewportChange={setViewport}
        >
          <Source type="vector" url="mapbox://pulcipheratdscc.dw17nvp5">
            <Layer {...countiesLayer} paint={paint} />
          </Source>
        </MapGL>
*/
